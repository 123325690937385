<template>
  <div
    class="flex h-full flex-col rounded-[10px] bg-white px-8 py-10 text-gray-900 dark:bg-gray-900 dark:text-gray-50"
  >
    <Spinner v-if="loadingInputs" />
    <Scheduling
      v-if="!loadingInputs"
      :inputs="inputs"
      :option="option"
      :from="from"
      :trigger-validation="triggerValidation"
      @input-update="updateInputs"
      @option-update="updateOption"
      @validationSuccess="saveInputs"
      @validationFailed="triggerValidation = false"
    />
    <div class="pl-[1px]">
      <Button
        v-if="!loadingInputs"
        @click="triggerValidation = true"
        class="mt-9 self-start"
      >
        Save Schedule
        <Spinner v-show="saving" size="small" class="ml-1 text-white" />
      </Button>
    </div>
  </div>
</template>

<script setup>
import { getWorkflow, updateWorkflow } from '@/apis/workflows'
import Button from '@/components/Button.vue'
import Scheduling from '@/components/scheduling/config.vue'
import { convertToProperFormat } from '@/components/scheduling/utility'
import Spinner from '@/components/Spinner.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { amplitudeTrackEvent } from '@/common/functions/eventTracker'
const inputs = ref({})
const option = ref(0)
const loadingInputs = ref(true)
const saving = ref(false)
const triggerValidation = ref(false)
const store = useStore()
const route = useRoute()

const emit = defineEmits(['success', 'error', 'changeToggleWatchRowValue'])

const props = defineProps({
  from: {
    type: String,
    default: 'builder'
  },
  watchRowToggle: {
    type: Boolean,
    default: false
  }
})

onMounted(async () => {
  try {
    if (!route.query.workflowId) {
      if (store._state.data.automationStore.automationScheduleData) {
        option.value =
          store._state.data.automationStore.automationScheduleData.schedule.option
        inputs.value =
          store._state.data.automationStore.automationScheduleData.schedule.rawData
      }
      loadingInputs.value = false
      return
    }
    const [response] = await Promise.all([getWorkflow(route.query.workflowId)])
    option.value = response.data.schedule?.option || 0
    inputs.value = response.data.schedule?.rawData || {}
    if (response.data?.isWatchRowEnabled) {
      emit('changeToggleWatchRowValue', response.data?.isWatchRowEnabled)
    }
    loadingInputs.value = false
  } catch (e) {
    emit('error', 'Something went wrong. Please try again in some time.')
  }
})

const updateInputs = updatedInputs => {
  inputs.value = updatedInputs
  if (inputs.value?.endDate) {
    const date = new Date(inputs.value?.endDate)
    const today = new Date()
    let obj = { ...updatedInputs }
    if (
      date.getUTCFullYear() === today.getUTCFullYear() &&
      date.getUTCMonth() === today.getUTCMonth() &&
      date.getUTCDate() === today.getUTCDate()
    ) {
      // Set the time to 23:59:59
      const newEndDate = new Date()
      newEndDate.setHours(23, 59, 59, 0)
      obj.endDate = newEndDate.toISOString()
      inputs.value = obj
    }
  }
}
const updateOption = newOption => {
  option.value = newOption
}

const saveInputs = async e => {
  try {
    if (Object.keys(e).length === 0) {
      store.dispatch('automationStore/addAutomationScheduleData', null)
      triggerValidation.value = false
      saving.value = false
      return
    }
    const structuredInput = convertToProperFormat(inputs.value, option.value)
    const optionSchedulingData = {
      startDate: inputs.value.startDate,
      endDate: inputs.value.endDate,
      startTime: inputs.value.startTime,
      endTime: inputs.value.endTime,
      option: option.value,
      timezone: Intl?.DateTimeFormat().resolvedOptions().timeZone
    }
    if (!route.query.workflowId) {
      store.dispatch('automationStore/addAutomationScheduleData', {
        schedule: {
          ...structuredInput,
          ...optionSchedulingData,
          rawData: { ...inputs.value }
        },
        isScheduled: props.watchRowToggle ? false : true,
        isActive: false,
        isWatchRowEnabled: props.watchRowToggle
      })
      amplitudeTrackEvent('Workflow Schedule', localStorage.getItem('email'), {
        schedule: {
          ...structuredInput,
          ...optionSchedulingData,
          rawData: { ...inputs.value }
        }
      })

      if (props.watchRowToggle) {
        emit('success', 'Watch Row Added Successfully')
      } else {
        emit('success', 'Schedule Updated successfully')
      }
    } else {
      saving.value = true
      await updateWorkflow(route.query.workflowId, {
        schedule: {
          ...structuredInput,
          ...optionSchedulingData,
          rawData: inputs.value
        },
        isScheduled: props.watchRowToggle ? false : true,
        isActive: false,
        isWatchRowEnabled: props.watchRowToggle
      })
      emit('success', 'Schedule updated successfully')
    }
  } catch (error) {
    console.log(error)
    emit(
      'error',
      'Failed to add or update the schedule. Please try again in some time.'
    )
  }
  triggerValidation.value = false
  saving.value = false
}
</script>
