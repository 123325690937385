<!-- 
  Props: 
  text: any string for the badge
  color: color of the badge. options: 'default', 'primary', 'important', 'added', 'removed'
  size: large, medium, small; default: medium
  variant: default, solid, stroke
  hasDotIndicator: boolean, default: false
  hasRemoveButton: boolean, default: false
  onRemoveButtonClick: function, default: null
  icon: icon name
 -->

<!-- TODO: CHANGE DOT AND REMOVE ICON COLOR according to COLOR PROP -->
<template>
  <span
    class="inline-flex items-center rounded-lg font-semibold"
    :class="[
      {
        'bg-gray-200  text-gray-600 dark:bg-gray-700 dark:text-gray-300':
          defaultColor,
        'bg-blue-100 text-blue-600 dark:bg-blue-900 dark:text-blue-50':
          primaryColor,
        'bg-red-600 text-red-50 dark:bg-red-500': importantColor,
        'bg-green-100 text-green-600 dark:bg-green-900 dark:text-green-50':
          addedColor,
        'bg-red-100 text-red-600 dark:bg-red-900 dark:text-red-50':
          removedColor,
        'px-3 py-1.5 text-sm': largeSize,
        'px-2 py-1 text-xs': smallSize,
        'border border-blue-600 bg-white text-blue-600 dark:border-blue-400 dark:bg-gray-900 dark:text-blue-400':
          strokeVariant,
        'bg-blue-600 text-blue-50 dark:bg-blue-500': solidVariant
      }
    ]"
  >
    <!-- dot indicator -->
    <SvgIcon name="dot-indicator" v-if="hasDotIndicator" />
    {{ text }}
    <SvgIcon :name="icon" v-if="icon" class="h-4 w-4" />

    <!-- remove button -->
    <button
      v-if="hasRemoveButton"
      type="button"
      class="ml-1 inline-flex h-4 w-4 shrink-0 items-center justify-center rounded-full text-blue-600 hover:bg-blue-200 hover:text-blue-700 focus:bg-blue-600 focus:text-white focus:outline-none dark:text-blue-300 dark:hover:bg-blue-500 dark:hover:text-blue-50"
      @click="onRemoveButtonClick"
    >
      <span class="sr-only"> Remove </span>
      <SvgIcon name="cross" />
    </button>
  </span>
</template>

<script>
import SvgIcon from './SvgIcon.vue'
export default {
  name: 'Badge',
  props: {
    text: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'default',
      validator(value) {
        // The value must match one of these strings
        return ['default', 'primary', 'important', 'added', 'removed'].includes(
          value
        )
      }
    },
    size: {
      type: String,
      default: 'large',
      validator(value) {
        // The value must match one of these strings
        return ['large', 'small'].includes(value)
      }
    },
    variant: {
      type: String,
      default: 'default',
      validator(value) {
        // The value must match one of these strings
        return ['default', 'stroke', 'solid'].includes(value)
      }
    },
    hasDotIndicator: {
      type: Boolean,
      default: false
    },
    hasRemoveButton: {
      type: Boolean,
      default: false
    },
    onRemoveButtonClick: {
      type: Function,
      default: null
    },
    icon: {
      type: String
    }
  },
  computed: {
    defaultColor() {
      return this.color === 'default'
    },
    primaryColor() {
      return this.color === 'primary'
    },
    importantColor() {
      return this.color === 'important'
    },
    addedColor() {
      return this.color === 'added'
    },
    removedColor() {
      return this.color === 'removed'
    },
    largeSize() {
      return this.size === 'large'
    },
    smallSize() {
      return this.size === 'small'
    },
    defaultVariant() {
      return this.variant === 'default'
    },
    strokeVariant() {
      return this.variant === 'stroke'
    },
    solidVariant() {
      return this.variant === 'solid'
    }
  },
  components: { SvgIcon }
}
</script>
