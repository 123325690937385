<template>
  <OpenResultsInGoogleSheetsModal
    v-if="googleSheetsModal"
    :modalData="modalData"
    @close="googleSheetsModal = false"
    @error="emitError"
    @success="e => $emit('success', e)"
  />
  <div>
    <Button
      v-if="showGoBack"
      @click="$emit('goBack')"
      color="tertiary"
      text="Go Back"
      leftIcon="left-arrow"
    />
  </div>

  <div v-if="hasOutputs === false">
    <div class="flex h-full items-center justify-center">
      <p v-if="$route.query.source === 'input'" class="text-lg">
        This Automation Did Not Return Any Results. Please Verify Your Input Or
        Try Using Different Inputs.
      </p>
      <p v-else class="text-lg">
        This Automation Step Will Display Data Once Completed. Thank You For
        Your Patience.
      </p>
    </div>
  </div>
  <div>
    <!-- TODO:might need later -->
    <!-- :class="[isWorkflow ? 'workflowTableClass' : 'tableClass']" -->
    <div class="workflowTableClass">
      <div
        class="bg-[#f8f9fa] flex flex-col items-start p-2 gap-2 border border-[#e9ecef] border-b-0"
      >
        <div class="flex justify-between w-full gap-8">
          <div class="flex gap-4">
            <div class="relative w-80">
              <input
                placeholder="Search In Results"
                v-model="search"
                class="block w-full rounded-lg pr-3 pl-11 border px-3 py-2.5 placeholder-gray-500 border-gray-300 caret-blue-600 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 transition-all duration-200 dark:placeholder-gray-400 sm:text-sm"
                autofocus
                v-debounce:300ms="keyValidate"
                :debounce-events="'keydown'"
              />
              <div class="absolute inset-y-0 left-0 flex items-center pl-3">
                <SvgIcon name="search" />
              </div>
            </div>

            <Select
              v-if="executionToggleOptions"
              :disabled="executionDropdownDisable"
              name="step selection"
              id="step-selection"
              class="block w-fit bg-white"
              :options="executionToggleOptions"
              v-model="tableSelectedExecutionNode"
              @change="nodeExecutionChange"
              hideClear
            />
            <Select
              v-if="workflowToggleOptions"
              name="step selection"
              id="step-selection"
              class="block w-fit bg-white"
              :options="workflowToggleOptions"
              v-model="tableSelectedNode"
              @change="nodeChange"
              hideClear
            />
          </div>

          <div class="flex gap-4">
            <div
              v-if="showLoadingText"
              class="flex items-center justify-center"
            >
              <p class="text-sm text-gray-500">Loading New Data ...</p>
            </div>
            <Button
              size="small"
              :leftIcon="csvLoader ? null : 'download'"
              :show-loader="csvLoader"
              text="Download CSV"
              @click="exportCSV()"
            />
            <Button
              size="small"
              class="text-white"
              leftIcon="external-link"
              color="successFilled"
              text="Open Google Sheet"
              @click="exportGoogleSheet()"
            />
          </div>
        </div>
        <div class="flex gap-2 flex-wrap">
          <div
            v-for="filter of filtersList"
            class="border-dashed border rounded-lg min-w-fit flex gap-1 items-center"
            :class="{
              'border-blue-600': focusedFilter === filter
            }"
            :key="filter"
          >
            <label class="text-xs text-gray-500 pl-2">{{
              columnMap[filter].label
            }}</label>
            <input
              :value="filters[filter]"
              @input="filters[filter] = $event.target.value"
              class="border-0 text-xs h-6 rounded-r-lg box-shadow-none focus:outline-none focus:ring-0"
              autofocus
              v-debounce:300ms="keyValidate"
              :debounce-events="'keydown'"
            />

            <svg-icon
              name="close"
              class="text-gray-400 hover:text-gray-600 cursor-pointer"
              @click="
                () => {
                  delete filters[filter]
                  refetch()
                }
              "
            />
          </div>
          <Popper v-if="columns" placement="bottom-start" :interactive="false">
            <Button
              size="small"
              left-icon="plus"
              color="secondary"
              text="Add filter"
              class="border-dashed h-6"
            />
            <template #content>
              <div class="bg-white border border-gray-200 rounded-lg shadow-lg">
                <Input
                  v-model="filterColumnSearch"
                  autofocus
                  class="p-2"
                  text="Filter by..."
                  @click.stop
                  tabindex="-1"
                />
                <div class="overflow-y-auto max-h-[256px]">
                  <div
                    v-for="column of filtersOptions.filter(
                      c =>
                        c.label
                          .toLowerCase()
                          .includes(filterColumnSearch.toLowerCase()) &&
                        !(c.name in filters)
                    )"
                    :key="column.name"
                    @click="onFilter(column.name)"
                    class="p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700 text-sm"
                    tabindex="0"
                    @keydown.enter="onFilter(column.name)"
                  >
                    {{ column.label }}
                  </div>
                </div>
              </div>
            </template>
          </Popper>
          <Button
            v-if="filtersList.length > 0 || search.length > 0"
            size="small"
            color="tertiary"
            text="Reset"
            @click="clearFilter()"
            class="h-6"
          />
        </div>
      </div>
      <ContextMenu ref="menu" :model="items" />
      <div v-if="outputData && utilityType">
        <div class="border-2 p-4">
          <!-- TODO: FIX json-viewer -->
          <!-- <json-viewer
            :value="{
              total: 25,
              limit: 10,
              skip: 0
            }"
          ></json-viewer> -->
          <pre class="text-wrap">{{ JSON.stringify(outputData, null, 2) }}</pre>
        </div>
      </div>
      <DataTable
        v-else-if="columns"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        columnResizeMode="expand"
        :exportFilename="csvFileName"
        :class="`p-datatable-sm`"
        dataKey="id"
        lazy
        :loading="loading"
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        :paginator="total > 100"
        :rows="100"
        resizableColumns
        ref="dt"
        removableSort
        stripedRows
        scrollable
        scrollHeight="flex"
        :totalRecords="total"
        v-model:selection="selectedItems"
        :value="output"
        :virtualScrollerOptions="{ itemSize: 46 }"
        @page="onPage($event)"
      >
        <Column
          v-for="(data, index) in columns"
          :field="data.name"
          :key="data._id"
          filter-field=""
          filterField="name"
          style="max-width: 20rem"
        >
          <template #header>
            <div
              class="flex items-center justify-between cursor-pointer group"
              @click="onSort(data.name)"
            >
              <span>{{ data.label }}</span>
              <svg-icon
                name="arrow-up-full"
                class="h-4 w-4"
                :class="{
                  'fill-gray-800': data.name === sortField,
                  'rotate-180': data.name === sortField && sortOrder === 'desc',
                  'rotate-0': data.name === sortField && sortOrder === 'asc',
                  'fill-gray-300 group-hover:fill-gray-600':
                    data.name !== sortField
                }"
              />
            </div>
          </template>
          <template #body="slotProps">
            <div
              @contextmenu="onRightClick($event, slotProps.data[data.name])"
              v-html="checkForUrl(slotProps.data[data.name])"
            ></div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script setup>
import ContextMenu from 'primevue/contextmenu'
import { downloadCsv } from '@/apis/automation-store/csv'
import { exportName } from '@/common/functions/csvFileNameGeneration'
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import Select from '@/components/Select.vue'
import OpenResultsInGoogleSheetsModal from '@/components/automationStore/OpenResultsInGoogleSheetsModal.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import SvgIcon from '@/components/SvgIcon.vue'
import { amplitudeTrackEvent } from '@/common/functions/eventTracker'

const props = defineProps({
  columnsData: {
    required: true,
    type: Array
  },
  filtersOptions: {},
  outputData: {
    required: true,
    type: Array
  },
  nodeId: {
    required: true,
    type: String
  },
  title: {
    type: String
  },
  total: {
    type: Number
  },
  isWorkflow: {
    type: Boolean
  },
  showGoBack: {
    type: Boolean
  },
  workflowToggleOptions: {
    type: Array
  },
  selectedNode: {},
  executionToggleOptions: {
    type: Array
  },
  selectedExecutionNode: {},
  executionDropdownDisable: {},
  showLoadingText: {},
  utilityType: {}
})

watch(
  () => props.nodeId,
  (newNode, oldNode) => {
    if (newNode !== oldNode) {
      reset()
    }
  }
)

watch(
  () => props.outputData,
  (newOutput, oldOutput) => {
    if (newOutput !== oldOutput) {
      output.value = newOutput
      loading.value = false
    }
  }
)

const emit = defineEmits([
  'error',
  'loadNextBatch',
  'refetch',
  'nodeChange',
  'nodeExecutionChange',
  'success'
])

const filters = ref(null)
const search = ref('')
const output = ref(null)
const columns = ref(null)
const selectedItems = ref(null)
const csvLoader = ref(null)
const route = useRoute()
const dt = ref()
const globalFilterArray = ref(null)
const lazyParams = ref({})
const loading = ref(false)
const googleSheetsModal = ref(false)
const modalData = ref(null)
const filterColumnSearch = ref('')
const focusedFilter = ref(null)
const sortField = ref(null)
const sortOrder = ref(null)
const tableSelectedNode = ref(null)
const tableSelectedExecutionNode = ref(null)
const hasOutputs = ref(false)

//right click menu
const items = ref([
  {
    label: 'Copy',
    icon: 'pi pi-copy',
    command: () => copyToClipboard()
  }
])

//temp stored copy data
const selectedCellData = ref(null)
const menu = ref()

//open menu and set the temp copy data
const onRightClick = (event, data) => {
  selectedCellData.value = data
  menu.value.show(event)
}
const copyToClipboard = async () => {
  await navigator.clipboard.writeText(selectedCellData.value)
  emit('success', 'Copied to Clipboard')
}

const columnMap = computed(() => {
  const map = {}
  columns.value.forEach(column => {
    map[column.name] = column
  })
  return map
})

const filtersList = computed(() => {
  return Object.keys(filters.value || {})
})

// clears all the filter data
const clearFilter = () => {
  initFilters()
  search.value = ''
  refetch()
}

onMounted(() => {
  tableSelectedNode.value = props.selectedNode
  tableSelectedExecutionNode.value = props.selectedExecutionNode

  if (props.outputData[0] == null) {
    return
  } else {
    hasOutputs.value = true
  }
  reset()
  globalFilterArray.value = Object.keys(props.outputData[0])
})

const reset = () => {
  filters.value = null
  columns.value = props.columnsData
  output.value = props.outputData
  selectedItems.value = null
  initFilters()
}

const csvFileName = computed(() => {
  return exportName(props.title)
})

const nodeChange = () => {
  emit('nodeChange', tableSelectedNode.value)
}

const nodeExecutionChange = () => {
  emit('nodeExecutionChange', tableSelectedExecutionNode.value)
}

const exportCSV = async () => {
  amplitudeTrackEvent('Data Exported', localStorage.getItem('email'), {
    exportType: 'CSV'
  })
  csvLoader.value = true
  let all = tableSelectedExecutionNode.value === 'all' ? 1 : 0
  try {
    const response = await downloadCsv(
      route.query.executionId,
      route.query.nodeId || props.nodeId,
      all
    )
    if (response['success']) {
      emit(
        'success',
        'Your Download Has Started Please Check The Exports Page To See Your Downloads'
      )
    } else {
      throw response.message
    }
  } catch (error) {
    emit('error', error)
  }
  csvLoader.value = false
}

const exportGoogleSheet = async () => {
  modalData.value = {
    executionId: route.query.executionId,
    workflowId: route.query.workflowId || route.params.id,
    inputSource: route.query.source
  }
  googleSheetsModal.value = true
}

// adds filter options
const initFilters = () => {
  filters.value = {}
}

const onPage = event => {
  lazyParams.value = event
  loading.value = true
  loadLazyData()
}

const loadLazyData = () => {
  emit('loadNextBatch', lazyParams.value.first)
}

const emitError = data => {
  console.log(data)
  emit('error', data)
}

const onFilter = col => {
  filters.value[col] = ''
}

const onSort = col => {
  let order = 'asc'
  let field = col

  if (field === sortField.value) {
    if (sortOrder.value === 'asc') {
      order = 'desc'
    } else {
      order = undefined
      field = undefined
    }
  }
  sortField.value = field
  sortOrder.value = order
  refetch()
}

const refetch = () => {
  loading.value = true

  emit('refetch', {
    search: search.value,
    filters: filters.value,
    sortField: sortField.value,
    sortOrder: sortOrder.value
  })
}

const keyValidate = (q, e) => {
  if (e.key === 'Shift' || e.key === 'Control' || e.key === 'Meta') {
    return
  } else {
    refetch()
  }
}

const checkForUrl = data => {
  const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i
  const telPattern = /^tel:/
  const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/
  const encodedUrl = encodeIfNotEncoded(data)
  if (urlPattern.test(encodedUrl)) {
    return `<a class='text-blue-700' href="${encodedUrl}" target="_blank">${encodedUrl}</a>`
  } else if (telPattern.test(data)) {
    let str = data.split(',')

    const phoneNumberHTML = str
      .map(
        phoneNumber =>
          `<a class="text-blue-700" href="${phoneNumber}">${phoneNumber}</a>`
      )
      .join('&nbsp;,&nbsp;')
    return `<span >${phoneNumberHTML}</span>`
  } else if (emailPattern.test(data)) {
    let str = data.split(',')
    const emailHTML = str
      .map(
        email => `<a class='text-blue-700' href="mailto:${email}" >${email}</a>`
      )
      .join('&nbsp;,&nbsp;')
    return `<span >${emailHTML}</span>`
  } else {
    return data
  }
}

/**
 * Ensures that a given URL is properly encoded.
 * If the URL is already encoded, it returns the URL as is.
 * If the URL is not encoded or encoding fails, it encodes the URL.
 *
 * @param {string} url - The URL to be checked and encoded if necessary.
 * @returns {string} - The encoded URL or the original URL if it's already encoded.
 *
 * @throws {URIError} - If the input URL is malformed and cannot be decoded.
 */
const encodeIfNotEncoded = url => {
  try {
    // Decode the URL to see if it throws an error
    const decodedUrl = decodeURI(url)

    // Re-encode the decoded URL using encodeURI
    const reEncodedUrl = encodeURI(decodedUrl)

    // Compare the original URL with the re-encoded URL
    if (url === reEncodedUrl) {
      return url // Already encoded, return as is
    } else {
      return reEncodedUrl // Was not encoded, return the encoded version
    }
  } catch (e) {
    // If decoding fails, the URL was not encoded, so we encode it
    return encodeURI(url)
  }
}
</script>

<style scoped>
:deep(.p-datatable-tbody) {
  font-family:
    Inter var,
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
}

:deep(.p-paginator-bottom) {
  border-width: 1px 1px 1px 1px !important;
}

.tableClass {
  height: calc(100vh - 240px);
}

.workflowTableClass {
  height: calc(100vh - 310px);
}
</style>
