<template>
  <AutomationHeader title="Exports" />
  <main
    class="flex min-h-0 min-w-0 flex-1 border-t border-gray-200 dark:border-gray-700"
  >
    <div class="flex h-full min-w-0 flex-1 flex-col px-4 py-5 sm:p-6">
      <!-- Show when data is empty -->
      <div
        v-if="total === 0 && !pageLoading"
        class="mt-10 flex h-full w-full flex-col items-center justify-center space-y-4"
      >
        <SvgIcon name="empty-data-light" />
        <p class="text-3xl font-bold text-gray-900 dark:text-gray-50">
          No Exports Available
        </p>
      </div>
      <!-- Table Container -->
      <div v-else class="flex-1 overflow-y-auto">
        <table class="min-w-full">
          <thead class="bg-gray-50 dark:bg-gray-700">
            <tr>
              <th
                scope="col"
                class="rounded-l-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
              >
                Name
              </th>
              <th
                scope="col"
                class="rounded-l-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
              >
                Created At
              </th>
              <th
                scope="col"
                class="rounded-l-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
              >
                Export To
              </th>
              <th
                scope="col"
                class="rounded-l-lg px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-50"
              >
                Status
              </th>
              <th scope="col" class="rounded-r-lg px-3 py-3.5 text-left">
                <span class="sr-only">Actions</span>
              </th>
            </tr>
          </thead>
          <tbody
            v-if="pageLoading"
            class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
          >
            <tr v-for="ele in 6" :key="ele">
              <td
                v-for="ele in 5"
                :key="ele"
                class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                <Skeleton height="40px" width="140px" borderRadius="8px" />
              </td>
            </tr>
          </tbody>
          <tbody
            v-else
            class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
          >
            <tr v-for="(data, index) in exportTableData" :key="data._id">
              <td
                class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                {{ data.exportName.match(/Result for workflow: (.*?),/)[1] }}
              </td>
              <td
                class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                {{ formatDateTime(data.createdAt) }}
              </td>
              <td
                class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                {{ data.exportTo }}
              </td>
              <td
                class="whitespace-nowrap flex items-center justify-center px-3 py-4 text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                <Popper
                  hover
                  v-if="
                    data.isExpired &&
                    data.exportTo === 'csv' &&
                    data.status != 'failed'
                  "
                >
                  <Badge text="Expired" color="default" />
                  <template #content>
                    <div class="w-max max-w-lg rounded bg-blue-50 p-2 text-sm">
                      This File is expired, Please re-request for a new file
                      from the result table
                    </div>
                  </template>
                </Popper>
                <Badge
                  v-else-if="data.status === 'completed'"
                  text="Completed"
                  color="added"
                />
                <Badge
                  v-else-if="data.status === 'failed'"
                  text="Failed"
                  color="removed"
                />
                <SvgIcon
                  class="h-5 w-6"
                  v-else-if="data.status === 'running'"
                  name="automationLoader"
                />
              </td>
              <td class="whitespace-nowrap px-3 py-4">
                <div class="flex items-center justify-end space-x-3 px-10">
                  <Button
                    v-if="
                      data.isExpired &&
                      data.exportTo === 'csv' &&
                      data.status != 'failed'
                    "
                    text="See Results"
                    color="tertiary"
                    @click="onSeeDataClick(data.resultURL)"
                    class="text-red-600"
                  />
                  <Button
                    v-else-if="data.exportTo === 'google-sheet'"
                    text="Go To Sheet"
                    color="success"
                    @click="onDownloadClick(data.resultURL)"
                  />
                  <Button
                    v-else-if="data.status === 'completed'"
                    text="Download"
                    @click="onDownloadClick(data.resultURL)"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Paginator -->
      <div v-if="total > 0" class="flex items-center justify-center mt-4">
        <Paginator
          @page="onPaginate"
          ref="page"
          v-model:first="first"
          :rows="20"
          :totalRecords="total"
          template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
        />
      </div>
    </div>
  </main>
</template>

<script setup>
import AutomationHeader from '@/components/automationStore/AutomationHeader.vue'
import Badge from '@/components/Badge.vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { getAllExports, getExport } from '@/apis/user'
import { onMounted, ref, onUnmounted } from 'vue'
import Popper from 'vue3-popper'
import { useRouter, useRoute } from 'vue-router'
import Paginator from 'primevue/paginator'

//variables
const router = useRouter()
const route = useRoute()
const exportTableData = ref(null)
const pageLoading = ref(true)
const count = ref(1)
const total = ref(0)
const first = ref(0)
const pollTimeout = ref(null)
const isPolling = ref(false)

/**
 *
 * Fetches export data on component mount and initializes polling if necessary.
 */
onMounted(async () => {
  try {
    await fetchData()
    if (total.value === 0) {
      return
    }
    if (route.query.page) {
      first.value = (route.query.page - 1) * 20
    }
    exportTableData.value.forEach((element, index) => {
      if (isExpired(element.createdAt)) {
        exportTableData.value[index].isExpired = true
      } else {
        exportTableData.value[index].isExpired = false
      }
    })
    // Start polling if there are running exports when component mounts
    const runningExports = exportTableData.value.filter(
      item => item.status === 'running'
    )
    if (runningExports.length > 0) {
      startPolling()
    }
  } catch (error) {
    console.log(error)
  } finally {
    pageLoading.value = false
  }
})

/**
 * Stop polling on component unmount
 */
onUnmounted(() => {
  stopPolling()
})

/**
 * Fetches the paginated export data from the server.
 * @async
 */
const fetchData = async () => {
  let start = 0
  if (route.query.page) {
    start = (route.query.page - 1) * 20
  }
  pageLoading.value = true
  const response = await getAllExports(start, 20)
  count.value = Math.ceil(response.total / 20)
  total.value = response.total
  exportTableData.value = response.data
  pageLoading.value = false
}

/**
 * Starts polling for export statuses and updates the table data.
 * @async
 */
const startPolling = async () => {
  // Prevent multiple polling instances
  if (isPolling.value) return

  try {
    // Mark polling as active
    isPolling.value = true

    // Filter only running exports
    const runningExports = exportTableData.value.filter(
      item => item.status === 'running'
    )

    // Stop if no running exports
    if (runningExports.length === 0) {
      stopPolling()
      return
    }

    // Extract IDs of running exports
    const runningExportIds = runningExports.map(item => item._id)

    // Fetch status for running exports
    const response = await getExport(runningExportIds.join(','))

    // Create a map of updated exports
    const updatedExportsMap = new Map(
      response.data.map(item => [item._id, item])
    )

    // Update exportTableData with completed statuses
    exportTableData.value = exportTableData.value.map(item => {
      if (item.status === 'running' && updatedExportsMap.has(item._id)) {
        const updatedItem = updatedExportsMap.get(item._id)

        // Replace running item with its completed version if status changed
        if (updatedItem.status !== 'running') {
          return updatedItem
        }
      }

      return item
    })

    // Continue polling if there are still running exports
    const stillRunningExports = exportTableData.value.filter(
      item => item.status === 'running'
    )

    if (stillRunningExports.length > 0) {
      // Continue polling with a timeout
      pollTimeout.value = setTimeout(startPolling, 5000)
    } else {
      // Stop polling if no more running exports
      stopPolling()
    }
  } catch (error) {
    console.error('Polling error:', error)
  } finally {
    // Ensure polling state is reset
    isPolling.value = false
  }
}

/**
 * Stops the polling process.
 */
const stopPolling = () => {
  if (pollTimeout.value) {
    clearTimeout(pollTimeout.value)
    pollTimeout.value = null
  }
  isPolling.value = false
}

/**
 * Formats a date string into a user-friendly format.
 * @param {string} dateString - The date string to format.
 * @returns {string} - The formatted date and time.
 */
function formatDateTime(dateString) {
  const date = new Date(dateString)
  const now = new Date()

  // Helper function to format time in 12-hour format with AM/PM
  const formatTime = date => {
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const period = hours >= 12 ? 'PM' : 'AM'
    const formattedHours = hours % 12 || 12 // Convert 0 to 12 for 12-hour format
    return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`
  }

  // Check if the date is today
  const isToday =
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()

  if (isToday) {
    return `Today ${formatTime(date)}`
  }

  // Format the date as "DD MMM YYYY"
  const day = date.getDate()
  const month = date.toLocaleString('default', { month: 'short' })
  const year = date.getFullYear()

  return `${day} ${month} ${year} ${formatTime(date)}`
}

/**
 * Checks if a date is more than 7 days old.
 * @param {string} dateString - The date string to check.
 * @returns {boolean} - True if expired, otherwise false.
 */
const isExpired = dateString => {
  const inputDate = new Date(dateString)
  const currentDate = new Date()
  const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000

  return currentDate - inputDate > sevenDaysInMs
}

/**
 * Handles the download action.
 * @param {string} url - The URL of the file to download.
 */
const onDownloadClick = async url => {
  window.open(url, '_blank')
}

/**
 * Navigates to the "See Data" view for a result.
 * @param {string} url - The URL of the result data.
 */
const onSeeDataClick = async url => {
  const urlArr = url.split('/')
  const executionId = urlArr[6].split('-')[1]
  await router.push(
    `/data-store/results?executionId=${executionId.slice(0, 24)}&workflowId=${
      urlArr[4]
    }`
  )
}

/**
 * Handles pagination.
 * @param {object} e - The pagination event object.
 */
const onPaginate = async e => {
  await updateQuery({
    page: Number(e.page + 1)
  })
  fetchData()
}

/**
 * Updates the query parameters in the URL.
 * @param {object} updates - The updated query parameters.
 */
const updateQuery = async updates => {
  await router.push({
    query: {
      ...route.query,
      ...updates
    }
  })
}
</script>

<style scoped>
:deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight) {
  background-color: theme('colors.blue.500');
  color: white;
}
:deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight):hover {
  background-color: theme('colors.blue.800');
  color: white;
}
</style>
