<template>
  <div class="flex-1">
    <div v-if="loading" class="flex items-center justify-center h-full">
      <Spinner size="large" />
    </div>
    <template v-else>
      <div class="mb-4 text-blue-500 flex items-center gap-2">
        <svg
          class="shrink-0 h-4 w-4"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <path
            opacity="0.12"
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            fill="currentColor"
          />
          <path
            d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p class="font-[500] text-blue-500">
          Make sure the selected spreadsheet contains header.
        </p>
      </div>
      <div class="grid grid-cols-2 gap-x-8">
        <div>
          <ValidationForm
            v-if="showForm"
            :key="inputData"
            class=""
            :platformInputs="platformInputs"
            :previousNodes="previousNodes"
            :inputs="inputData"
            :triggerValidation="triggerValidation"
            :triggerFormStatus="triggerFormStatus"
            @validationSuccess="validationSuccess"
            @validationFailed="validationFailed"
            @change="selectUpdate"
            @input-update="onInputUpdate"
            @input-blur="onInputBlur"
            @form-status="checkFormStatus"
          />
          <span
            v-show="formloading"
            class="flex items-center justify-center text-gray-300"
          >
            Checking for more inputs
            <Spinner size="small" class="ml-1 text-gray-400" />
          </span>
        </div>

        <div class="flex flex-col gap-3">
          <ValidationForm
            v-if="showForm"
            class=""
            :platformInputs="platformInputs1"
            :previousNodes="previousNodes"
            :inputs="inputData1"
            :triggerValidation="triggerValidation"
            @validationFailed="validationFailed"
            @input-update="onInputUpdate1"
          />

          <div class="flex items-center">
            <button
              type="button"
              class="elative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-400 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:bg-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-900"
              role="switch"
              name="slack_executionSuccess"
              aria-checked="false"
              :aria-checked="loopCheck.toString()"
              @click=";(loopCheck = !loopCheck), change($event, 'loopCheck')"
              :class="{
                '!bg-blue-600 dark:bg-blue-500': loopCheck,
                'bg-gray-400 dark:bg-gray-500': !loopCheck
              }"
            >
              <span
                aria-hidden="true"
                class="pointer-events-none inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                :class="{
                  'translate-x-5': loopCheck,
                  'translate-x-0': !loopCheck
                }"
              ></span>
            </button>
            <span class="ml-4 flex flex-grow flex-col" id="">
              <span
                class="text-sm font-semibold text-gray-900 dark:text-gray-50"
              >
                Loop mode
              </span>
              <span class="text-sm text-gray-500 mt-[1.5] dark:text-gray-400">
                Loop back again to re-process the Google Sheet from the start
                after last row is reached.
              </span>
            </span>
          </div>
          <div class="mt-14 3xl:mt-[75px]">
            <label
              class="block text-sm font-medium text-gray-900 dark:text-gray-50"
            >
              Add New Google Sheet Account:
            </label>
            <img
              @click="$emit('GoogleSignInClick', 'google-sheets')"
              class="h-[46px] mt-[3px] cursor-pointer"
              src="@/assets/images/logos/btn_google_signin_dark_normal_web@2x.png"
              alt="signin-with-google"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import Select from '@/components/Select.vue'
import Spinner from '@/components/Spinner.vue'
import ValidationForm from '@/components/ValidationForm.vue'
import { mapActions, mapState } from 'vuex'
const googlePlatformId = import.meta.env.VITE_GOOGLE_SHEETS_PLATFORMID
import {
  AUTOMATION_VARIABLE_DIRECTION,
  AUTOMATION_VARIABLE_TYPES,
  constants
} from '@/common/constants'

//API
import {
  getGoogleHeaders,
  getGoogleToken
} from '@/apis/automation-store/googleSheets'
import ObjectID from 'bson-objectid'
import { getConnectedAccounts } from '@/apis/automation-store/Page1'
import { getWorkflowNode, getWorkflowAllNodes } from '@/apis/workflows'
import { getOperationVariableServices } from '@/apis/getPlatformDetails'
export default {
  name: 'GoogleSheetFormValidation',
  components: {
    Select,
    Input,
    Button,
    ValidationForm,
    Spinner
  },
  data() {
    return {
      showForm: false,
      connectedAccounts: null,
      googleAccountData: [],
      platformInputs: [],
      platformInputs1: [],
      triggerValidation: false,
      triggerFormStatus: false,
      spreadsheetId: null,
      sheetId: null,
      sheetInputData: null,
      loading: true,
      formloading: false,
      inputData: {},
      inputData1: {},
      previousNodes: [],
      loopCheck: false,
      validationResult: false,
      validationResult1: false
    }
  },
  props: {
    validateForm: {
      required: true
    }
  },
  async created() {
    await this.getAccounts()
    this.showForm = true
    this.triggerFormStatus = true
  },
  watch: {
    validateForm(newVal, oldVal) {
      if (newVal === true) {
        this.triggerValidation = true
      }
    },
    validationResult(newVal, oldVal) {
      this.checkAndUpdateValidationStatus()
    },
    validationResult1(newVal, oldVal) {
      this.checkAndUpdateValidationStatus()
    }
  },
  computed: {
    ...mapState('automationStore', ['automationStoreData']),
    ...mapState('automationStore', ['connectedAccountId']),
    ...mapState('automationStore', ['connectedAccountIdGoogleSheets']),
    ...mapState('automationStore', ['automationInputs']),
    ...mapState('workflow', ['dynamicOutputs']),
    ...mapState('automationStore', ['automationInputs'])
  },
  methods: {
    ...mapActions('automationStore', ['addConnectedAccountId']),
    ...mapActions('automationStore', ['addConnectedAccountIdGoogleSheets']),
    ...mapActions('workflow', ['setDynamicOutputs']),
    ...mapActions('workflow', ['resetVariables']),
    ...mapActions('automationStore', ['setValidationStatus']),
    ...mapActions('automationStore', ['addConnectedAccountId']),
    ...mapActions('automationStore', ['addAutomationInputData']),
    ...mapActions('automationStore', ['addAutomationData']),

    checkAndUpdateValidationStatus() {
      if (this.validationResult && this.validationResult1) {
        this.setValidationStatus({
          payload: true
        })
      } else {
        this.setValidationStatus({
          payload: false
        })
      }
    },
    async getAccounts() {
      this.$emit('setDesktopToggle')
      const googleAccountresponse = await getConnectedAccounts(googlePlatformId)

      this.platformInputs.push({
        name: 'connectedAccountId',
        type: 'select',
        label: 'Select Google Account:',
        isRequired: true,
        choices: googleAccountresponse.data.map(account => ({
          value: account._id,
          label: account.name
        }))
      })

      //check if inputs are stored in vuex
      if (
        Object.keys(this.automationInputs).length > 0 &&
        this.automationInputs.googleSheetId !== undefined
      ) {
        this.inputData['connectedAccountId'] =
          this.automationStoreData.connectedAccountIdGoogleSheets
        this.$emit(
          'googleConnectedAccountId',
          this.automationStoreData.connectedAccountIdGoogleSheets
        )

        this.platformInputs = [...this.automationStoreData.googlePlatformInputs]
        for (const obj of this.platformInputs) {
          if (obj.name === 'googleSpreadsheetId') {
            // obj.choices = [...this.automationStoreData.googleSpreadsheetId]
          } else if (obj.name === 'googleSheetId') {
            if (this.automationStoreData.googleSheetId) {
              obj.choices = [...this.automationStoreData.googleSheetId]
              const keys = this.dynamicOutputs.keys()
              const nodeId = keys.next().value
              this.previousNodes = [
                {
                  id: nodeId,
                  label: 'Enter Input',
                  order: 1,
                  platformId: '62986487c9e1b344befa8ddc',
                  platformOperationId: '628335fad0cc02a4b44b6fb7',
                  logoUrl:
                    'https://assets-of-v2.s3.amazonaws.com/platforms/logos/google-sheets.svg'
                }
              ]
            }
          }
        }
        // prefill all the inputs stored in vuex
        this.inputData = this.automationInputs

        this.inputData['connectedAccountId'] =
          this.connectedAccountIdGoogleSheets

        if (this.connectedAccountId) {
          this.inputData1['platformConnectedAccountId'] =
            this.connectedAccountId
          this.validationResult1 = true
        }
      } else if (
        //check if vuex is empty as well as node id is provided in url
        Object.keys(this.automationInputs).length === 0 &&
        !!this.$route.query.nodeId
      ) {
        const platformResponse = await getOperationVariableServices(
          constants.googlePlatformId,
          constants.googlePlatformOperationId,
          AUTOMATION_VARIABLE_DIRECTION.INPUT,
          this.$route.query.nodeId
        )
        const nodeRes = await getWorkflowAllNodes(this.$route.query.workflowId)

        this.platformInputs.push(
          ...platformResponse.data.filter(item => item.label !== 'Loop')
        )

        this.addAutomationData({
          payload: {
            googlePlatformInputs: this.platformInputs
          }
        })
        await this.dynamicObj(
          this.$route.query.nodeId,
          nodeRes.data[0].dynamicOutputs
        )

        //set the inputs with the api inputs
        this.inputData = {
          ...this.inputData,
          ...nodeRes.data[0].inputs,
          ...nodeRes.data[1].inputs
        }

        this.$emit(
          'googleConnectedAccountId',
          this.inputData.connectedAccountIdGoogleSheets
        )
        this.addAutomationInputData({
          payload: this.inputData
        })

        this.inputData['connectedAccountId'] =
          nodeRes.data[0].connectedAccountId
        this.addConnectedAccountIdGoogleSheets({
          payload: nodeRes.data[0].connectedAccountId
        })

        this.inputData1['platformConnectedAccountId'] =
          nodeRes.data[1].connectedAccountId
        this.addConnectedAccountId({
          payload: nodeRes.data[1].connectedAccountId
        })
        this.validationResult1 = true
      } else {
        this.platformInputs.push(
          ...this.automationStoreData.variablesData.filter(
            item => item.label !== 'Loop'
          )
        )
        this.inputData['connectedAccountId'] =
          googleAccountresponse?.data[0]?._id
        this.addConnectedAccountIdGoogleSheets({
          payload: googleAccountresponse?.data[0]?._id
        })

        this.$emit(
          'googleConnectedAccountId',
          googleAccountresponse?.data[0]?._id
        )
      }

      if (this.automationStoreData.authType) {
        if (this.automationStoreData.isSocialAccountOptional) {
          this.validationResult1 = true
          this.loading = false
          return
        }
        let response = await getConnectedAccounts(
          this.automationStoreData.platformId
        )
        this.connectedAccounts = response.data
        if (this.automationStoreData.platform === 'LinkedIn') {
          //add isPremium when accounts are added
          this.addIsPremium()
        }

        this.platformInputs1.unshift({
          name: 'platformConnectedAccountId',
          type: 'select',
          label: `Account:`,
          isRequired: this.automationStoreData.isOptional ? false : true,
          choices: response.data.map(account => ({
            value: account._id,
            label: account.name
          })),
          description:
            this.automationStoreData.platform === 'Google'
              ? `Your Rocket Scrape/Scraper API Account`
              : `Your ${this.automationStoreData.platform} Account`
        })

        //no account found redirect user to integration
        if (
          response.data.length === 0 &&
          this.automationStoreData.isOptional === false
        ) {
          this.$emit('showRedirect')
          this.loading = false
          return
        }

        if (
          this.automationStoreData.authType &&
          this.connectedAccountId == null
        ) {
          //prefill the input with first account
          if (this.automationStoreData.isOptional === false) {
            this.inputData1['platformConnectedAccountId'] =
              response?.data[0]?._id
          }
          if (response?.data[0]?._id) {
            this.validationResult1 = true
          }
          this.platformInputs1 = this.platformInputs1.map(input => {
            if (input.name === 'platformConnectedAccountId') {
              return {
                ...input,
                leftImageUrl: response?.data[0]?.platform?.picture
              }
            }
            return input
          })
          this.addConnectedAccountId({
            payload: response?.data[0]?._id
          })
        }
      } else {
        this.validationResult1 = true
      }

      this.loading = false
    },

    async selectUpdate(e, input, value) {
      if (input?.name === 'connectedAccountId') {
        this.addConnectedAccountIdGoogleSheets({
          payload: e
        })
        return
      }

      if (input?.name === 'platformConnectedAccountId') {
        //connectedAccountId flow started
        this.addConnectedAccountId({
          payload: e
        })
        this.formloading = false
        return
      }

      if (input?.name === 'googleSpreadsheetId') {
        this.spreadsheetId = e
        this.inputData['googleSpreadsheetId'] = e
        return
      }

      if (input?.name === 'googleSheetId') {
        this.sheetId = e
        this.platformInputs.splice(
          this.automationStoreData.variablesData.length
        )
        await this.getSheetColumnHeader(
          this.inputData.googleSpreadsheetId ?? this.spreadsheetId,
          e
        )
        return
      }
    },
    async dynamicObj(nodeId, dynamicOutputs) {
      this.resetVariables()
      this.setDynamicOutputs({
        nodeId,
        dynamicOutputs: dynamicOutputs
      })
      this.addAutomationData({
        payload: { dynamicOutputs: dynamicOutputs }
      })
      for (let data of this.automationStoreData.inputList) {
        let obj = {
          ...data
        }
        delete obj['position']
        this.platformInputs.push({
          ...obj,
          hasDependencyOnFields: [
            'connectedAccountId',
            'googleSpreadsheetId',
            'googleSheetId'
          ]
        })
      }

      this.previousNodes = [
        {
          id: nodeId,
          label: 'Enter Input',
          order: 1,
          platformId: '62986487c9e1b344befa8ddc',
          platformOperationId: '628335fad0cc02a4b44b6fb7',
          logoUrl:
            'https://assets-of-v2.s3.amazonaws.com/platforms/logos/google-sheets.svg'
        }
      ]
    },
    async getSheetColumnHeader(spreadsheetId, sheetId) {
      try {
        this.formloading = true
        const response = await getGoogleHeaders(
          this.connectedAccountIdGoogleSheets,
          spreadsheetId,
          sheetId
        )
        let nodeId
        if (response['success']) {
          if (this.dynamicOutputs.size === 0) {
            nodeId = ObjectID().toHexString()
          } else {
            const keys = this.dynamicOutputs.keys()
            nodeId = keys.next().value
          }
          this.addAutomationData({
            payload: { dynamicOutputs: response.data }
          })
          this.resetVariables()
          this.setDynamicOutputs({
            nodeId,
            dynamicOutputs: response.data
          })

          for (let data of this.automationStoreData.inputList) {
            let obj = {
              ...data
            }
            delete obj['position']
            this.platformInputs.push({
              ...obj,
              hasDependencyOnFields: [
                'connectedAccountId',
                'googleSpreadsheetId',
                'googleSheetId'
              ]
            })
          }
          if (this.automationStoreData.platform === 'LinkedIn') {
            //add isPremium when automation fields are added
            this.addIsPremium()
          }

          this.previousNodes = [
            {
              id: nodeId,
              label: 'Get data',
              order: 1,
              platformId: '62986487c9e1b344befa8ddc',
              platformOperationId: '628335fad0cc02a4b44b6fb7',
              logoUrl:
                'https://assets-of-v2.s3.amazonaws.com/platforms/logos/google-sheets.svg'
            }
          ]
        } else {
          throw response.message
        }
      } catch (e) {
        console.log(e)
        this.$emit('error', e)
      } finally {
        this.formloading = false
      }
    },
    validationFailed() {
      this.triggerValidation = false
      this.$emit('resetFromValidation')
    },
    validationSuccess() {
      this.validationResult = true
      if (this.validationResult1 === false) {
        this.validationFailed()
        return
      }
      this.setValidationStatus({
        payload: true
      })
      this.$emit('resetFromValidation')
      this.triggerValidation = false
      this.$emit('submitCreate', {
        spreadsheetId: this.spreadsheetId,
        sheetId: this.sheetId,
        sheetInputData: this.sheetInputData,
        loopCheck: this.loopCheck
      })
    },
    async onInputUpdate1(inputs, validationResult) {
      for (const input in inputs) {
        if (input === 'platformConnectedAccountId') {
          this.addConnectedAccountId({
            payload: inputs[input] === '' ? null : inputs[input]
          })
        }

        this.addAutomationInputData({
          payload: { [input]: inputs[input] }
        })
      }

      if (validationResult.valid) {
        this.validationResult1 = true
      } else {
        this.validationResult1 = false
      }
      const inputObj = { ...inputs }

      // update connected account picture on every change
      const account = this.connectedAccounts?.find(
        acc => acc._id === inputObj.platformConnectedAccountId
      )
      this.platformInputs1 = this.platformInputs1.map(input => {
        if (input.name === 'platformConnectedAccountId') {
          return {
            ...input,
            leftImageUrl: account?.platform?.picture
          }
        }
        return input
      })
      if (this.automationStoreData.platform === 'LinkedIn') {
        //update isPremium when account is changed
        this.addIsPremium(account)
      }
    },
    async onInputUpdate(inputs, validationResult) {
      for (const input in inputs) {
        if (input === 'connectedAccountId') {
          continue
        }
        this.addAutomationInputData({
          payload: { [input]: inputs[input] }
        })
      }

      this.addAutomationData({
        payload: {
          googlePlatformInputs: this.platformInputs
        }
      })
      if (validationResult.valid) {
        this.validationResult = true
      } else {
        this.validationResult = false
      }
      for (const data of this.automationStoreData.inputList) {
        if (inputs[data.name]) {
          this.sheetInputData = {
            ...this.sheetInputData,
            [data.name]: inputs[data.name]
          }
        }
      }
    },
    checkFormStatus(data) {
      if (data.valid) {
        this.validationResult = true
      } else {
        this.validationResult = false
      }
      this.triggerFormStatus = false
    },
    /**
     * Updates the `isPremium` property of the input with the name 'messageContent' in `platformInputs`
     * based on the provided account or the account found from `connectedAccounts`.
     *
     * @param {Object|null} [account=null] - The account object to determine premium status. If null, the function
     * will attempt to find the account using `platformConnectedAccountId` from `inputData1`.
     * @param {string} account._id - The unique identifier of the account (optional if account is provided).
     * @param {boolean} account.isPremium - The premium status of the account.
     *
     * @returns {void} This function does not return anything. It modifies `platformInputs` in place.
     */
    addIsPremium(account = null) {
      if (!account && !this.inputData1?.platformConnectedAccountId) {
        return
      }
      
      const selectedAccount =
        account ||
        this.connectedAccounts.find(
          ({ _id }) => _id === this.inputData1?.platformConnectedAccountId
        )

      this.platformInputs = this.platformInputs.map(input => {
        if (input.name === 'messageContent') {
          return {
            ...input,
            isPremium: Boolean(selectedAccount?.isPremium)
          }
        }
        return input
      })
    }
  }
}
</script>

<style></style>
