<template>
  <div ref="container">
    <div
      class="box-border flex border-2 cursor-pointer flex-col items-center justify-center truncate rounded-lg border-blue-500"
      @dblclick="openInputEditor"
      :class="[
        {
          'bg-blue-50 ': selected,
          'bg-white ': !selected,
          ' border-yellow-400 ': errorMessages?.length
        }
      ]"
      :style="{
        height: NODE_SIZES[NODE_TYPE_NAMES.AUTOMATION_NODE] + 'px',
        width: NODE_SIZES[NODE_TYPE_NAMES.AUTOMATION_NODE] + 'px'
      }"
    >
      <!-- listen to popper open and close events to change z-index so that the popper appears on top of other nodes  -->
      <Popper
        v-if="errorMessages?.length"
        hover
        @click.stop
        @dblclick.stop
        class="absolute right-0.5 top-0.5"
        placement="right"
        locked
        :offset-distance="6"
        @open:popper="container.parentElement.style.zIndex = '1000'"
        @close:popper="container.parentElement.style.zIndex = '0'"
      >
        <SvgIcon name="warning" class="animate-pulse stroke-yellow-500 p-0.5" />
        <template #content>
          <ul class="rounded bg-white p-2 text-xs shadow-md">
            <li v-for="message in errorMessages">* {{ message }}</li>
          </ul>
        </template>
      </Popper>
      <NodeMenu
        v-if="menuOpenId === id"
        class="absolute bottom-28"
        @option-clicked="onOptionClick"
      />
      <img
        class="h-10 w-10 rounded-full object-cover"
        :src="data.automationData.logoUrl"
        alt=""
      />

      <!-- <SvgIcon
      name="3-dot-menu-horizontal"
      class="absolute top-2 right-2 h-1 w-5 fill-gray-200 hover:fill-blue-500"
      @click.stop="e => $emit('toggle-menu', id)"
    /> -->
      <div
        v-for="{ position, isActive } in allHandles"
        class="group absolute z-10 flex h-[24px] w-[24px] items-center justify-center"
        @mouseenter="hoverHandle = position"
        @mouseleave="hoverHandle = null"
        :style="{
          ...getPosForHandle(position)
        }"
        @click="isActive && openRightPanel($event, position)"
        @dblclick="isActive && openRightPanel($event, position)"
      >
        <SvgIcon
          v-if="isActive"
          class="h-[12px] isPlusSign w-[12px] rounded-full text-white transition-transform"
          name="plus"
          :class="{
            'scale-[2]':
              hoverHandle === position ||
              (rightPanelSelectedNode?.nodeId === id &&
                rightPanelSelectedNode?.position === position),
            'scale-0':
              hoverHandle !== position &&
              !(
                rightPanelSelectedNode?.nodeId === id &&
                rightPanelSelectedNode?.position === position
              ),
            'bg-blue-500': position === 'right' || position === 'left',
            'bg-blue-200': position !== 'right' && position !== 'left'
          }"
        />
      </div>
      <Handle
        v-for="{ position, connected } in allHandles"
        :id="position"
        :type="getHandleType(position)"
        :position="position"
        class="h-[10px] w-[10px]"
        :class="{
          'bg-gray-500': !connected,
          'bg-blue-500':
            connected && (position === 'right' || position === 'left'),
          'bg-blue-200':
            connected && position !== 'right' && position !== 'left'
        }"
        :connectable="false"
      />
    </div>
    <p
      class="absolute -left-[10px] mt-2 w-[100px] overflow-hidden rounded-lg bg-white bg-opacity-40 p-1 text-center text-xs font-semibold text-gray-900 group-hover:text-blue-600 dark:text-gray-50 dark:group-hover:text-gray-50"
    >
      {{ data.order ? `${data.order}.` : '' }} {{ label }}
    </p>
  </div>
</template>

<script setup>
import { NODE_SIZES, NODE_TYPE_NAMES } from '@/common/constants'
import SvgIcon from '@/components/SvgIcon.vue'
import { Handle, Position, useVueFlow } from '@vue-flow/core'
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import NodeMenu from '../nodeMenu.vue'
import { onNodeDeleted } from '../workflowNodeEventsHandler'

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  type: {
    type: String
  },
  label: {
    type: String
  },
  data: {
    type: Object
  },
  position: {
    type: Object
  },
  menuOpenId: {
    type: String
  },
  selected: {
    type: Boolean
  },
  errorMessages: Array,
  rightPanelSelectedNode: {}
})

const emit = defineEmits([
  'updateNodeInternals',
  'error',
  'open-panel',
  'toggle-menu',
  'open-input-editor',
  'update:node-loader'
])
const { nodes, edges, removeNodes, removeSelectedNodes, getSelectedNodes } =
  useVueFlow()

const allHandles = ref([])
const route = useRoute()
const hoverHandle = ref()
const container = ref()

const getPosForHandle = pos => {
  if (pos === Position.Left) {
    return {
      top: NODE_SIZES[NODE_TYPE_NAMES.AUTOMATION_NODE] / 2 - 12 + 'px',
      left: '-12px'
    }
  }
  if (pos === Position.Right) {
    return {
      top: NODE_SIZES[NODE_TYPE_NAMES.AUTOMATION_NODE] / 2 - 12 + 'px',
      right: '-12px'
    }
  }
  if (pos === Position.Top) {
    return {
      left: NODE_SIZES[NODE_TYPE_NAMES.AUTOMATION_NODE] / 2 - 12 + 'px',
      top: '-12px'
    }
  }
  if (pos === Position.Bottom) {
    return {
      left: NODE_SIZES[NODE_TYPE_NAMES.AUTOMATION_NODE] / 2 - 12 + 'px',
      bottom: '-12px'
    }
  }
}

// set all the handles of this node
const setHandles = () => {
  // if the previous is connected on the left edge or if this is the first node
  if (props.data.targetHandle === Position.Left || !props.data.targetHandle) {
    const outputHandles = edges.value
      .filter(e => e.source === props.id)
      .map(e => e.sourceHandle)
      .concat(
        edges.value.filter(e => e.target === props.id).map(e => e.targetHandle)
      )
    allHandles.value = Object.values(Position).map(pos => ({
      position: pos,
      isActive:
        pos === Position.Left ||
        pos === Position.Right ||
        !outputHandles.includes(pos), // deactivate already added secondary node dot
      connected: outputHandles.includes(pos)
    }))
  } else {
    // secondary nodes
    allHandles.value = [
      { position: props.data.targetHandle, isActive: false, connected: true }
    ]
  }
}

onMounted(() => {
  setHandles()
})

watch(
  () => edges.value.length,
  () => setHandles()
)

const getHandleType = pos =>
  props.data.targetHandle === pos ? 'target' : 'source'

const onOptionClick = async () => {
  emit('update:node-loader', true)
  await onNodeDeleted(route.params.id, [props], nodes, edges, removeNodes)
  emit('update:node-loader', false)
  emit('toggle-menu')
}

const openInputEditor = () => {
  emit('open-input-editor', {
    id: props.id,
    ...props.data
  })
}

const openRightPanel = (e, position) => {
  e.stopPropagation()
  removeSelectedNodes(getSelectedNodes.value)
  emit('open-panel', props, position)
}
</script>
