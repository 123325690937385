<template>
  <!-- START WRAPPER -->

  <div class="overflow-hidden">
    <table class="min-w-full">
      <thead class="bg-gray-50 dark:bg-gray-700">
        <tr>
          <th
            scope="col"
            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
          >
            Limit
          </th>
          <th
            scope="col"
            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
          >
            Total
          </th>
          <th
            scope="col"
            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-50"
          >
            Left
          </th>
        </tr>
      </thead>
      <!-- Skeleton loader -->

      <tbody
        class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
        :key="componentKey"
      >
        <tr v-for="(item, key) in limitstableBodyData" :key="key" ref="user">
          <td class="whitespace-nowrap px-3 py-4">
            <span class="text-sm text-gray-900 dark:text-gray-50">
              {{ keyToMap[key] }}
            </span>
          </td>
          <td
            v-for="(ele, index) in Object.values(item)"
            class="whitespace-nowrap px-3 py-4 data-primary"
          >
            <div v-html="getColors(ele, item, index, key)"></div>
            <!-- <span class="text-sm text-gray-900 dark:text-gray-50">
                    {{ ele }}
                  </span> -->
          </td>
        </tr>
      </tbody>
    </table>

    <hr />
    <p class="font-bold text-blue-500 text-center text-xl">
      Organization Proxy Info
    </p>
    <table>
      <tbody
        class="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900"
        :key="componentKey"
      >
        <tr v-for="(item, key) in orgProxyData" :key="key">
          <td class="whitespace-nowrap p-2 flex items-center">
            <p class="text-sm text-gray-900 dark:text-gray-50">
              {{ keyToMap[key] }}
            </p>
            <p>:&nbsp;</p>
            <p class="text-gray-900 font-bold">
              {{
                key === 'lastBandwidthSyncedAt'
                  ? item === ''
                    ? `Not Synced Yet`
                    : getFormatDate(item)
                  : isNaN(item)
                  ? 0
                  : `${Math.max(0, item)} GB`
              }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <hr />
    <div class="grid grid-cols-2 gap-2 py-4 items-center">
      <div
        class="flex justify-between gap-2 px-3"
        v-for="(data, key) in limitsRemainingData"
      >
        <p>{{ keyToMap[key] }}:</p>
        <p
          class="font-bold"
          :class="data === true ? 'text-green-500' : 'text-red-700'"
        >
          {{ data === true ? 'Yes' : 'No' }}
        </p>
      </div>
    </div>
  </div>

  <!-- END WRAPPER -->
</template>

<script>
import Input from '@/components/Input.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Button from '@/components/Button.vue'
import {
  formatExecutionTime,
  formatDate,
  formatTime
} from '@/common/functions/formatDateAndTime'

export default {
  name: 'remainingLimitsTable',
  components: {
    Input,
    SvgIcon,
    Button
  },
  props: {
    limitstableBodyData: {
      type: Object,

      required: true
    },
    limitsRemainingData: {
      type: Object,
      required: true
    },
    orgProxyData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      keyToMap: {
        members: 'Members',
        workspaces: 'Workspaces',
        concurrentExecution: 'Concurrent Execution',
        socialAccounts: 'Social Accounts',
        executionTime: 'Cloud Run Time',
        emailEnrichment: 'Data Enrichment',
        publicScraperCredit: 'Public Scraper Credit',
        apiAccess: 'API Access',
        isPayAsYouGo: 'Pay As You Go',
        aiAccess: 'AI Access',
        desktopUnlimited: 'Desktop Access',
        integrationsAccess: 'Integration Access',
        desktopUnlimitedExecutionTime: 'Desktop Unlimited',
        proxyAccess: 'Proxy Access',
        total: 'Total',
        lastBandwidthSyncedAt: 'Last Bandwidth Synced At',
        consumed: 'Consumed'
      }
    }
  },
  /**
   * Formats and applies color classes to a given value based on conditions.
   *
   * @param {string|number} ele - The value to be processed.
   * @param {Object} item - The current item containing the `total` property.
   * @param {number} index - The index of the current element.
   * @param {string} key - The key indicating which field is being processed (e.g., 'executionTime').
   * @returns {string} - The formatted HTML string with appropriate color classes.
   */
  methods: {
    getColors(ele, item, index, key) {
      const num = Number(ele)
      let eleToDisplay = isNaN(num) ? 0 : Math.max(0, num)
      if (index === 1) {
        var percentageLeft = (eleToDisplay / item.total) * 100

        if (key === 'executionTime') {
          eleToDisplay = formatExecutionTime(eleToDisplay)
        }

        // Apply color classes based on percentage
        if (percentageLeft > 75) {
          return `<p class='text-green-500 font-bold ' >${eleToDisplay}</p>`
        } else if (percentageLeft >= 25 && percentageLeft <= 75) {
          return `<p class='text-orange-500 font-bold ' >${eleToDisplay}</p>`
        } else {
          return `<p class='text-red-700 font-bold ' >${eleToDisplay}</p>`
        }
      } else {
        if (key === 'executionTime') {
          let displayEle = formatExecutionTime(eleToDisplay)
          return `<p class='text-gray-900 font-bold ' >${displayEle}0</p>`
        }
        return `<p class='text-gray-900 font-bold ' >${eleToDisplay}</p>`
      }
    },
    getFormatDate(d) {
      return `${formatDate(d)} ${formatTime(d)}`
    }
  }
}
</script>

<style scoped></style>
