<template>
  <div
    class="absolute left-0 top-0 z-50 mx-auto grid h-full w-full place-items-center bg-transparent/50 px-4 py-12 antialiased dark:bg-transparent/50 sm:px-6 lg:px-8"
  >
    <div
      class="relative w-full max-w-3xl ml-[6vh] justify-center overflow-hidden rounded-xl bg-white dark:bg-gray-900"
    >
      <div class="px-4 py-5 sm:px-6">
        <div class="flex items-center justify-end">
          <Button
            @click="close(false)"
            icon="close-1"
            class="!p-1.5"
            size="small"
            color="tertiary"
          />
        </div>
        <p
          class="text-center text-xl font-bold text-gray-900 dark:text-gray-50"
        >
          Connect New Account
        </p>
      </div>

      <div class="px-4 pb-5 sm:px-6 sm:pb-6">
        <div class="mx-auto flex max-w-sm  items-center justify-center">
          <img
            class="mx-auto h-16 w-16 shrink-0 rounded-full object-cover ring-1 ring-blue-600 ring-offset-2 dark:ring-blue-500 dark:ring-offset-gray-900"
            src="@/assets/images/symbols/texau.svg"
            alt=""
          />

          <div class="flex items-center justify-center">
            <SvgIcon
              name="rightArrowFilled"
              class="h-5 w-5 text-blue-600 opacity-20 dark:text-blue-500"
            />
            <SvgIcon
              name="rightArrowFilled"
              class="h-5 w-5 text-blue-600 opacity-40 dark:text-blue-500"
            />
            <SvgIcon
              name="rightArrowFilled"
              class="h-5 w-5 text-blue-600 opacity-60 dark:text-blue-500"
            />
            <SvgIcon
              name="rightArrowFilled"
              class="h-5 w-5 text-blue-600 opacity-80 dark:text-blue-500"
            />
            <SvgIcon
              name="rightArrowFilled"
              class="h-5 w-5 text-blue-600 dark:text-blue-500"
            />
          </div>

          
          <div class="flex mx-auto -space-x-2 overflow-hidden ">
                <img
                  v-for="img in integrationData.logoUrl"
                  class="  mx-auto h-16 w-16 shrink-0 rounded-full object-cover ring-1 ring-offset-2 dark:ring-offset-gray-900"
                  :src="img"
                />
              </div>
        </div>
        <Spinner size="large" class="mx-auto my-16" v-if="isLoading" />

        <div
          v-if="
            !isLoading &&
            integrationData.auth &&
            integrationData.auth.type === 'cookie' &&
            noInputs === false
          "
        >
          <div class="mt-8">
            <div class="flex justify-around">
              <button
                @click="onImportClick($event)"
                :data-platform="integrationData._id"
                :data-sync="false"
                :name="
                  integrationData.type === 'automation'
                    ? 'texau-extension-v2'
                    : ''
                "
                class="border-blue-600 inline-flex items-center justify-center rounded-lg border font-semibold px-4 py-2.5 text-base sm:text-sm transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 bg-transparent text-blue-600 hover:bg-blue-100 focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:border-blue-400 dark:text-blue-400 dark:hover:bg-blue-400 dark:hover:text-blue-50 dark:focus:ring-blue-400 dark:focus:ring-offset-gray-900"
              >
                <SvgIcon
                  id="texau-v2-extension-import-loader"
                  class="hidden"
                  name="spinnerIcon"
                />
                Install Extension
              </button>
              <Button
                color="tertiary"
                :showLoader="buttonLoader"
                @click="generateMagicLink()"
                text="Share Via Magic Link"
              />
            </div>
            <div
              id="texau-v2-extension-import-success"
              class="mt-4 hidden overflow-hidden rounded-lg bg-green-100 dark:bg-green-900/50"
            >
              <div class="px-4 py-3">
                <div class="flex items-center">
                  <SvgIcon name="check" />
                  <span
                    class="ml-3 text-sm font-medium text-green-700 dark:text-green-300"
                  >
                    Account connected successfully!
                  </span>
                </div>
              </div>
            </div>
            <div
              id="texau-v2-extension-import-failed"
              class="mt-4 hidden overflow-hidden rounded-lg bg-red-100 dark:bg-red-900/50"
            >
              <div class="px-4 py-3">
                <div class="flex items-center">
                  <SvgIcon class="h-5 w-5 text-red-700" name="cancel" />
                  <span
                    class="ml-3 text-sm font-medium text-red-700 dark:text-red-300"
                  >
                    Error occurred while connecting account, Please try again.
                  </span>
                </div>
              </div>
            </div>
            <div v-if="magicLink">
              <div class="mt-4 rounded-lg bg-blue-50 dark:bg-blue-900/50">
                <div class="p-2">
                  <div class="flex items-start justify-between">
                    <SvgIcon class="h-5 w-5 text-blue-600" name="info" />

                    <p
                      class="ml-3 mr-auto flex-1 text-sm font-normal text-gray-900 dark:text-gray-50"
                    >
                      Allow on-behalf client account authorization by sharing
                      the link below:
                    </p>
                  </div>
                </div>
              </div>
              <div class="relative mt-4">
                <Input
                  v-model="magicLink"
                  :key="magicLink"
                  :readonly="true"
                  :right-icon="magicLink === '' ? '' : 'copy'"
                  right-icon-mouse="cursor-pointer"
                  @onRightIconClick="copy()"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="!isLoading && noInputs === false">
          <div class="mt-8 grid grid-cols-2 gap-x-6 gap-y-5">
            <strong class="col-span-2 text-xs">
              Please make sure that you have selected the workspace you want
              your integration on
            </strong>
            <div v-if="integrationData.update" class="col-span-2 flex">
              <p class="text-sm">
                {{ integrationData.label }} Account Name:&nbsp;
              </p>
              <p class="text-sm font-bold">{{ integrationData.accountName }}</p>
            </div>
            <ValidationForm
              class="col-span-2"
              :platformInputs="platformInputs"
              :previousNodes="[]"
              :inputs="{}"
              :triggerValidation="triggerValidation"
              @validationSuccess="submitCreate"
              @validationFailed="triggerValidation = false"
            />
          </div>

          <div class="col-span-2 flex flex-row-reverse">
            <Button
              class=""
              :leftIcon="
                !buttonSpinner && integrationData.update
                  ? 'refresh'
                  : !buttonSpinner && !integrationData.update
                  ? 'plus'
                  : ''
              "
              :show-loader="buttonSpinner"
              :text="integrationData.update ? 'Update' : 'Add New'"
              @click="triggerValidation = true"
            />
          </div>
        </div>

        <div
          v-if="!loading && noInputs"
          class="mt-8 flex items-center justify-center"
        >
          <strong>NO INPUTS PRESENT AT THE MOMENT...</strong>
        </div>

        <!-- TODO: will be needed for later -->
        <!-- <div>
            <Button
              class="w-full"
              leftIcon="google"
              text="Connect in with Google"
              color="tertiary"
            />
          </div>

          <div>
            <Button
              text="Share via magic link"
              color="secondary"
              class="inline-flex w-full items-center justify-center rounded-lg border border-blue-600 bg-transparent px-4 py-2.5 text-base font-semibold text-blue-600 transition-all duration-200 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 dark:border-blue-400 dark:text-blue-400 dark:hover:bg-blue-400 dark:hover:text-blue-50 dark:focus:ring-blue-400 dark:focus:ring-offset-gray-900 sm:text-sm"
              @click="magicLink = !magicLink"
            />
          </div> -->
        <!-- </div> -->

        <!-- <div v-show="magicLink">
          <div class="mt-8 rounded-lg bg-blue-50 dark:bg-blue-900/50">
            <div class="p-4">
              <div class="flex items-start">
                <SvgIcon
                  name="info"
                  class="h-5 w-5 shrink-0 text-blue-500 dark:text-blue-300"
                />
                <p
                  class="ml-3 flex-1 text-sm font-medium text-gray-900 dark:text-gray-50"
                >
                  Allow on-behalf client account authorization by sharing the
                  link below:
                </p>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <label for="" class="sr-only"> Copy link </label>

            <div class="relative">
              <Input
                type="password"
                text="https://app.texau.com/integrations/sheets/accounts/187866-386896"
              />

              <div class="absolute inset-y-0 right-0 flex items-center pr-3">
                <Button
                  icon="copy"
                  color="iconOnly"
                  class="p-1 text-base font-semibold text-blue-600 transition-all duration-200 hover:text-blue-700 focus:outline-none focus:ring-0 dark:text-blue-400 dark:hover:text-blue-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        </div> -->
      </div>

      <button class="hidden" @click="$emit('goBack')" id="go-back-to-accounts">
        click and go back
      </button>
    </div>
  </div>
</template>

<script>
import {
  getAuthVariables,
  postAccountIntegration,
  updateAccountIntegration
} from '@/apis/settings/settings-integration'

import { createLink } from '@/apis/accountImport'

import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import Select from '@/components/Select.vue'
import Spinner from '@/components/Spinner.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import ValidationForm from '@/components/ValidationForm.vue'
import { validateCredentials } from '@/apis/connectedAccounts'
import { constants } from '@/common/constants'

export default {
  name: 'connectNewAccountModal',
  components: {
    SvgIcon,
    Button,
    Select,
    Input,
    ValidationForm,
    Spinner
  },
  data() {
    return {
      isLoading: true,
      triggerValidation: false,
      platformInputs: null,
      noInputs: false,
      buttonSpinner: false,
      magicLink: null,
      buttonLoader: false
    }
  },
  props: {
    integrationData: { type: Object, required: true }
  },
  async mounted() {
    await this.getVariables()
  },
  computed: {
    text() {
      return `Enter ${this.integrationData.name} Account Name`
    },
    webhookText() {
      return `Enter ${this.integrationData.name} Webhook Link`
    },
    label() {
      return `Add ${this.integrationData.name} Account Name`
    }
  },
  methods: {
    async onImportClick(e) {
      if (e.currentTarget.innerText === 'Install Extension') {
        //TODO: open chrome extension store link in new tab
        var newTab = window.open(
          'https://chromewebstore.google.com/detail/texau-v2/hbhdepogdnphlloancjiifodpchlkeam',
          '_blank'
        )
        newTab.focus()
      } else {
        return
      }
    },
    close(flag) {
      if (flag) {
        this.$emit('close', 'fetch')
      } else {
        this.$emit('close')
      }

      this.magicLink = false
    },
    async getVariables() {
      try {
        const response = await getAuthVariables(
          this.integrationData._id || this.integrationData.platformId
        )
        if (response.data.length === 0) {
          this.noInputs = true
          this.isLoading = false
          return
        }

        let data = response.data

        for (let ele of data) {
          ele.isRequired = true
        }

        this.platformInputs = data
      } catch (error) {
        this.emitFailureMessage(
          'Error Getting in Variables Please Try Again Later'
        )
      }

      if (this.integrationData.update === false) {
        this.platformInputs.unshift({
          isRequired: true,
          type: 'text',
          description: 'Platform Account Name',
          label: `${this.integrationData.label} Account Name`,
          name: 'name'
        })
      }

      this.isLoading = false
    },

    async submitCreate(inputs) {
      this.triggerValidation = false
      this.buttonSpinner = true
      let credentialsData = { ...inputs }
      delete credentialsData['name']

      try {
        let PostResponse
        let response = await validateCredentials(
          credentialsData,
          this.integrationData._id
        )

        if (response['success']) {
          this.emitSuccessMessage('credentials are verified successfully')
          if (this.integrationData.update === false) {
            let newData = {
              name: inputs.name ?? this.integrationData.accountName,
              platform: {
                id: this.integrationData._id
              },
              credentials: {
                data: credentialsData,
                isExpired: false
              }
            }
            PostResponse = await postAccountIntegration(newData)
          } else {
            let newData = {
              credentials: {
                data: credentialsData,
                isExpired: false
              }
            }
            PostResponse = await updateAccountIntegration(
              newData,
              this.integrationData.accountId
            )
          }

          if (PostResponse['success']) {
            this.emitSuccessMessage(
              ` ${this.integrationData.label} Integration Added Successfully`
            )
          } else {
            this.emitFailureMessage(
              'Some Error Occured While Adding Integration'
            )
          }
          this.buttonSpinner = false
        } else {
          this.emitFailureMessage(
            'Validation failed please check your credentials'
          )
          this.buttonSpinner = false
        }
        this.buttonSpinner = false
        this.close(true)
      } catch (error) {
        this.emitFailureMessage('Some Error Occured While Adding Integration')
        this.buttonSpinner = false
        this.close(false)
      }
    },

    async generateMagicLink() {
      try {
        this.buttonLoader = true

        if (this.integrationData._id === constants.LINKEDIN_PLATFORM_ID) {
          await this.generateLinkedinLinks()
        } else {
          await this.generatePlatformLink()
        }
      } catch (e) {
        this.emitFailureMessage(e)
      } finally {
        this.buttonLoader = false
      }
    },

    async generateLinkedinLinks() {
      try {
        const [linkedinLink, salseNavLink, recruiterLink] = await Promise.all([
          createLink({
            platformId: constants.LINKEDIN_PLATFORM_ID
          }),
          createLink({
            platformId: '64267ae1dbfc2b4d1fa6628d'
          }),
          createLink({
            platformId: '6541d862e7e500bea9c8b645'
          })
        ])
        if (window.location.href.includes('v2-prod.texau.com')) {
          this.magicLink = `https://v2-prod.texau.com/new-account/?linkIds=${linkedinLink.data.linkId},${salseNavLink.data.linkId},${recruiterLink.data.linkId}`
        } else {
          this.magicLink = `https://v2-beta.texau.com/new-account/?linkIds=${linkedinLink.data.linkId},${salseNavLink.data.linkId},${recruiterLink.data.linkId}`
        }

        this.emitSuccessMessage('Magic link created successfully')
      } catch (error) {
        throw error
      }
    },
    async generatePlatformLink() {
      try {
        const response = await createLink({
          platformId:
            this.integrationData._id || this.integrationData.platformId
        })
        if (response['success']) {
          if (window.location.href.includes('v2-prod.texau.com')) {
            this.magicLink = `https://v2-prod.texau.com/new-account/?linkIds=${response.data.linkId}`
          } else {
            this.magicLink = `https://v2-beta.texau.com/new-account/?linkIds=${response.data.linkId}`
          }

          this.emitSuccessMessage('Magic link created successfully')
        } else {
          throw response.message
        }
      } catch (error) {
        throw error
      }
    },

    emitSuccessMessage(message) {
      this.$emit('updateNotificationApiResponse', {
        type: 'success',
        value: message
      })
    },
    emitFailureMessage(message) {
      this.$emit('updateNotificationApiResponse', {
        type: 'fail',
        value: message
      })
    },
    async copy() {
      try {
        await navigator.clipboard.writeText(this.magicLink)
        this.emitSuccessMessage('Copied')
        setTimeout(this.removeCopiedContainer, 2000)
      } catch (error) {
        this.emitFailureMessage('Some Error Occured While Copying the Link')
      }
    },
    removeCopiedContainer() {
      this.copied = false
    }
  }
}
</script>

<style scoped></style>
