<template>
  <tr v-for="(row, index) of tableData" :key="row._id">
    <td
      class="group px-3 py-3.5 text-sm font-semibold text-gray-900 dark:text-gray-50"
    >
      <input
        type="checkbox"
        :value="row._id"
        v-model="workflowIdToDelete"
        class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-gray-800 sm:left-6"
        @change="updateCheckall()"
      />
    </td>
    <td
      class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
    >
      <Popper hover :disabled="row?.name?.length <= 27">
        <p class="truncate lg:max-w-[12rem] md:max-w-[8rem]">
          {{ row.name }}
        </p>
        <template #content>
          <div class="w-max max-w-lg rounded bg-blue-50 p-2 text-sm">
            {{ row.name }}
          </div>
        </template>
      </Popper>
    </td>

    <td
      class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
    >
      {{ `${getFormatDateAndTime(row.createdAt)}` }}
    </td>

    <td
      class="whitespace-nowrap w-[170px] px-3 py-4 text-sm text-gray-900 dark:text-gray-50"
    >
      {{
        row.isRunning
          ? getFormatExecutionTime(
              row.executions.totalElapsedTime + elapsedTime
            )
          : row.executions && row.executions.status
          ? getFormatExecutionTime(row.executions.totalElapsedTime)
          : row.isActive
          ? 'Active'
          : 'Inactive'
      }}
    </td>

    <td
      class="whitespace-nowrap px-3 py-4 text-sm capitalize text-gray-900 dark:text-gray-50"
    >
      {{
        row.executions
          ? `${getFormatExecutionTime(row.executions.totalExecutionTime)}`
          : '0hr 0min 0sec'
      }}
    </td>

    <td class="whitespace-nowrap px-3 py-4">
      <div>
        <div class="flex gap-2 capitalize" v-if="row.resultSeen">
          <button
            type="button"
            class="flex items-center justify-center text-gray-500"
          >
            <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 8 8">
              <circle cx="4" cy="4" r="3" />
            </svg>
          </button>
          Inactive
        </div>
        <div
          class="flex gap-2 capitalize"
          v-else-if="row.executions && row.executions.status"
        >
          <SvgIcon
            class="h-5 w-6"
            v-if="row.executions.status === 'running'"
            name="automationLoader"
          />
          <Popper v-else hover="">
            <button
              type="button"
              class="flex items-center justify-center"
              :class="[
                {
                  'text-orange-500':
                    row.executions.status === 'paused' ||
                    row.executions.status === 'waiting',
                  'text-red-600': row.executions.status === 'stopped',
                  'text-red-600': row.executions.status === 'failed',
                  'text-green-600': row.executions.status === 'completed',
                  'text-gray-500': status === 'delayed',
                  'text-black': status === 'partially-failed'
                }
              ]"
            >
              <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 8 8">
                <circle cx="4" cy="4" r="3" />
              </svg>
            </button>
            <template #content>
              <div class="w-max rounded bg-blue-50 p-2 text-sm">
                {{ row.executions?.statusReason || row.executions.status }}
              </div>
            </template>
          </Popper>
          {{
            row.executions.status === 'paused'
              ? row.executions?.statusLabel || row.executions?.statusReason
              : row.executions.status
          }}
        </div>
        <div v-else class="flex gap-2">
          <button
            type="button"
            class="flex items-center justify-center text-gray-500"
          >
            <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 8 8">
              <circle cx="4" cy="4" r="3" />
            </svg>
          </button>
          {{ row.isActive ? 'Active' : 'Inactive' }}
        </div>
      </div>
    </td>

    <td class="whitespace-nowrap px-3 py-4">
      <div class="flex -space-x-2 overflow-hidden">
        <img
          class="inline-block h-8 w-8 rounded-full ring-2 ring-white dark:ring-gray-900"
          :src="img"
          alt=""
          v-for="img in row.uniquePlatformIconUrl"
        />
      </div>
    </td>

    <td class="whitespace-nowrap px-3 py-4">
      <div class="flex items-center space-x-4">
        <span class="text-base font-normal text-gray-900 dark:text-gray-50">
          <Popper v-if="row?.isWatchRowEnabled" hover position="top" :arrow="true">
            <div
                  class="border border-blue-600 bg-transparent text-sm text-blue-600 px-3 py-2 font-semibold rounded-md"
                  disabled
                >
                  Watch Row
            </div>
            <template #content>
              <div v-if="row.schedule?.option" class="w-[500px] border rounded bg-white dark:bg-gray-800 shadow-lg p-6 text-base">
                <div class="text-wrap text-sm flex flex-col gap-4">
                  <div class="flex items-center gap-2 text-yellow-600 dark:text-yellow-400 bg-yellow-50 dark:bg-yellow-900/30 p-3 rounded-md">
                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
                    </svg>
                    <p class="font-medium">
                      Watch Row is enabled - workflow will run when your google sheet row changes are detected
                    </p>
                  </div>
                  <div class="border-t text-center dark:border-gray-700 pt-4">
                    <p class="font-semibold text-gray-900 dark:text-gray-100 mb-2">
                      Schedule Details
                    </p>
                    <p class="text-gray-700 dark:text-gray-300">
                      {{ scheduleOptions[row.schedule.option] }}
                      {{ row.schedule.option === 1 
                        ? `(Every ${row.schedule.minutes} minutes)` 
                        : row.schedule.rawData?.time 
                          ? `at ${formatScheduleTime(row.schedule.rawData?.time)}` 
                          : '' 
                      }}
                      {{ row.schedule.option === 4 && row.schedule.rawData?.days 
                        ? ` on ${formatDays(row.schedule.rawData.days)}` 
                        : '' 
                      }}
                      {{ row.schedule.option === 5 && row.schedule.rawData?.dates 
                        ? ` on ${formatDates(row.schedule.rawData.dates)}` 
                        : '' 
                      }}
                      {{ row.schedule.option === 6 && row.schedule.rawData?.months 
                        ? ` in ${formatMonths(row.schedule.rawData.months)} on ${formatDates(row.schedule.rawData.dates)}` 
                        : '' 
                      }}
                      {{ row.schedule.timezone ? `(${row.schedule.timezone})` : '' }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </Popper>
          <div v-else-if="row.isScheduled">
            <Button
              @click="stopSchedule(row._id, index)"
              :text="
                row?.isInternalSchedule === true ? 'Stop' : 'Stop Schedule'
              "
              size="small"
              color="danger"
            />
          </div>
          <div v-else>No</div>
        </span>
      </div>
    </td>

    <td class="whitespace-nowrap px-3 py-4">
      <div class="flex items-center gap-x-2 text-gray-400 dark:text-gray-50">
        <Button
          text="Open"
          color="secondary"
          :showLoader="isLoading && index === loaderIndex"
          @click="
            onOpenClick(
              row._id,
              row.createdFrom,
              row.inputSource,
              index,
              row?.executions?.status,
              row?.executions?.latestExecutionId,
              row?.startNode
            )
          "
        />

        <div class="w-[8rem] flex items-start">
          <Button
            v-if="
              row.hasResult &&
              !(
                row.executions.status === 'running' &&
                row.createdFrom === 'automation-store' &&
                row.inputSource === 'input'
              )
            "
            text="See Data"
            color="success"
            :showLoader="isLoadingData && index === loaderIndex"
            @click="
              onSeeDataClick(
                row.executions.latestExecutionId,
                row._id,
                row.inputSource,
                index
              )
            "
          />
        </div>
      </div>
    </td>

    <td v-if="userRole !== 'viewer'" class="whitespace-nowrap px-3 py-4">
      <div class="">
        <button
          type="button"
          class="-m-2 rounded-lg p-2 text-gray-600 transition-all duration-200 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-0 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-50"
          @click="
            $emit('trigger-menu', {
              ref: $refs.buttons[index],
              id: row._id,
              iconUrl: row.uniquePlatformIconUrl,
              name: row.name,
              showTop: tableData.length - 1 === index ? true : false
            })
          "
          ref="buttons"
        >
          <span class="sr-only"> Open dropdown menu </span>
          <div class="h-5 w-5">
            <SvgIcon name="3-dot-menu" />
          </div>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import {
  getWorkflow,
  getWorkflowAllNodes,
  getWorkflowNode,
  pauseSchedule
} from '@/apis/workflows'
import { WORKFLOW_CREATED_FROM } from '@/common/constants'
import Backdrop from '../Backdrop.vue'
import StatusDot from '../StatusDot.vue'
import SvgIcon from '../SvgIcon.vue'
import Button from '@/components/Button.vue'
import {
  formatDate,
  formatTime,
  formatExecutionTime
} from '@/common/functions/formatDateAndTime'
import Popper from 'vue3-popper'
import { mapState } from 'vuex'

export default {
  name: 'WorkflowTableBody',
  props: {
    propsTableData: {
      type: Array,
      required: true
    },
    isCheckAll: {
      type: Boolean
    },
    workflowIdArr: {
      type: Array
    }
  },
  emits: ['error', 'success', 'warning', 'trigger-menu', 'updateWorkflowArr'],
  components: { StatusDot, SvgIcon, Backdrop, Button, Popper },
  computed: { ...mapState('settings', ['userRole']) },
  data() {
    return {
      showMenuFor: null,
      workflowId: null,
      url: null,
      loaderIndex: null,
      isLoading: false,
      isLoadingData: false,
      tableData: this.propsTableData,
      workflowIdToDelete: this.workflowIdArr,
      elapsedTime: 0,
      counterInterval: null,
 scheduleOptions : [
  'None',
  'At Regular Intervals',
  'Once',
  'Every Day',
  'Days Of The Week',
  'Days Of The Month',
  'Specific Dates'
]
    }
  },
  mounted() {
    this.startCounter()
  },
  unmounted() {
    // Clear the interval to stop the counter when the component is destroyed
    clearInterval(this.counterInterval)
  },
  watch: {
    propsTableData() {
      this.tableData = this.propsTableData
    },
    workflowIdArr() {
      this.workflowIdToDelete = this.workflowIdArr
    }
  },
  methods: {
    updateCheckall() {
      this.$emit('updateWorkflowArr', this.workflowIdToDelete)
    },
    getFormatDateAndTime(d) {
      return `${formatDate(d)} ${formatTime(d)}`
    },

    startCounter() {
      // Start the counter by incrementing elapsedTime every second
      this.counterInterval = setInterval(() => {
        this.elapsedTime += 1000 // Increment by 1 second
      }, 1000)
    },

    getFormatExecutionTime(ms) {
      return `${formatExecutionTime(ms)}`
    },

    async onOpenClick(
      workflowId,
      createdFrom,
      inputSource,
      index,
      status,
      executionId,
      startNode
    ) {
      this.loaderIndex = index
      this.isLoading = true
      try {
        if (
          createdFrom === WORKFLOW_CREATED_FROM.BUILDER ||
          createdFrom === WORKFLOW_CREATED_FROM.API
        ) {
          if (status === 'running') {
            await this.$router.push(
              `/workflow-builder/${workflowId}?executionId=${executionId}`
            )
          } else {
            await this.$router.push(`/workflow-builder/${workflowId}`)
          }

          return
        } else if (createdFrom === WORKFLOW_CREATED_FROM.STORE) {
          const response = await getWorkflowNode(workflowId, startNode)

          if (response['success']) {
            if (inputSource === 'sheet' || inputSource === 'csv') {
              const response = await getWorkflowAllNodes(workflowId)
              let nodeId = response.data[0]._id
              let secondNodeId = response.data[0].nextNode
              let operationId = response.data[1].platformOperationId
              let platformId = response.data[1].platformId
              this.url = `/automation-store/workflow?workflowId=${workflowId}&operationId=${operationId}&platformId=${platformId}&nodeId=${nodeId}&secondNodeId=${secondNodeId}&source=${inputSource}`

              await this.$router.push(this.url)

              return
            }
            const operationId = response.data.platformOperationId
            let platformId = response.data.platformId
            let nodeId = response.data._id
            this.url = `/automation-store/workflow?workflowId=${workflowId}&operationId=${operationId}&platformId=${platformId}&nodeId=${nodeId}&source=${inputSource}`

            await this.$router.push(this.url)
          }
        }
      } catch (error) {
        this.$emit('error')
      }
    },

    async onSeeDataClick(executionId, workflowId, inputSource, index) {
      this.loaderIndex = index
      this.isLoadingData = true
      const response = await getWorkflow(workflowId)
      if (response.data.createdFrom === WORKFLOW_CREATED_FROM.BUILDER) {
        await this.$router.push(
          `/data-store/results?executionId=${executionId}&workflowId=${workflowId}`
        )
        return
      }
      const startNode = response.data.startNode
      let nodeId, platformId, operationId, outputMode
      if (inputSource === 'sheet' || inputSource === 'csv') {
        const response = await getWorkflowAllNodes(workflowId)
        ;({ nodeId, operationId, platformId, outputMode } = this.parseResponse(
          response.data[1]
        ))
      } else {
        const response = await getWorkflowNode(workflowId, startNode)
        ;({ nodeId, operationId, platformId, outputMode } = this.parseResponse(
          response.data
        ))
      }
      const url = `/automation-store/results?workflowId=${workflowId}&executionId=${executionId}&operationId=${operationId}&platformId=${platformId}&nodeId=${nodeId}&source=${inputSource}&outputMode=${outputMode}`
      await this.$router.push(url)
    },
    parseResponse(data) {
      return {
        nodeId: data._id,
        operationId: data.platformOperationId,
        platformId: data.platformId,
        outputMode: data.outputMode
      }
    },

    async stopSchedule(id, index) {
      try {
        const response = await pauseSchedule(id)
        if (response['success']) {
          this.tableData[index].isScheduled = false
          this.$emit('success', 'Automation Schedule Stopped Successfully')
        } else {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },
   /**
 * Formats a schedule time into a human-readable string.
 * 
 * @param {Object} time - The time object to format.
 * @param {number} time.hour - The hour of the time (0-23).
 * @param {number} time.minute - The minute of the time (0-59).
 * @param {string} time.phase - The phase of the time (AM/PM).
 * @returns {string} The formatted time string (e.g., "1:30 PM").
 */
formatScheduleTime(time) {
  if (!time) return '';
  
  const hour = time.hour === 12 ? 12 : time.hour % 12;
  const minute = time.minute.toString().padStart(2, '0');
  const phase = time.phase.toUpperCase();
  
  return `${hour}:${minute} ${phase}`;
},

/**
 * Converts an array of numeric day indices to their corresponding names.
 * 
 * @param {number[]} days - An array of day indices (0 = Sunday, 1 = Monday, ..., 6 = Saturday).
 * @returns {string} A comma-separated string of day names.
 */
formatDays(days) {
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return days.map(day => dayNames[day]).join(', ');
},

/**
 * Formats an array of dates by adding ordinal suffixes and sorting them.
 * 
 * @param {number[]} dates - An array of date numbers.
 * @returns {string} A comma-separated string of formatted dates with ordinal suffixes.
 */
formatDates(dates) {
  return dates
    .sort((a, b) => a - b)
    .map(date => {
      const suffix = this.getOrdinalSuffix(date);
      return `${date}${suffix}`;
    })
    .join(', ');
},

/**
 * Gets the ordinal suffix for a given date.
 * 
 * @param {number} date - The date to get the ordinal suffix for.
 * @returns {string} The ordinal suffix ("st", "nd", "rd", or "th").
 */
getOrdinalSuffix(date) {
  if (date > 3 && date < 21) return 'th';
  switch (date % 10) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
  }
},

/**
 * Converts an array of numeric month indices to their corresponding names.
 * 
 * @param {number[]} months - An array of month indices (0 = January, 1 = February, ..., 11 = December).
 * @returns {string} A comma-separated string of month names.
 */
formatMonths(months) {
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  return months
    .sort((a, b) => a - b)
    .map(month => monthNames[month])
    .join(', ');
}

  }
}
</script>
