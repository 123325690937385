import axios from '@/common/axios'

const getBaseUser = async () => {
  try {
    const response = await axios.get('/base-users')
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const startMfaAssociation = async () => {
  try {
    const response = await axios.post(
      '/base-users/enable-mfa/start-association'
    )
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const confirmAssociation = async data => {
  try {
    const response = await axios.post(
      '/base-users/enable-mfa/confirm-association',
      data
    )
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const disableMfa = async () => {
  try {
    const response = await axios.post('/base-users/disable-mfa')
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const updateUserDetail = async data => {
  try {
    const response = await axios.put('/base-users', data)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const getUsers = async () => {
  try {
    const response = await axios.get('/users')
    return response.data
  } catch (error) {
    handleError(error, 'An error occurred while fetching the users.')
  }
}

const pinAutomation = async platformOperationId => {
  try {
    const response = await axios.post(
      `/base-users/pin-automation/${platformOperationId}`
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const unpinAutomation = async platformOperationId => {
  try {
    const response = await axios.post(
      `/base-users/unpin-automation/${platformOperationId}`
    )
    return response.data
  } catch (error) {
    return error.response.data
  }
}

const getOrganisationLimits = async () => {
  try {
    const response = await axios.get('organisations/limits/ui')
    return response.data
  } catch (e) {
    return e.response.data
  }
}

/**
 * Sends OTP to registered phone number.
 * @returns {Promise<Object>} - The response data or an error object.
 */
const verifyPhone = async () => {
  try {
    const response = await axios.post(`/auth/verify/phone`)
    return response.data
  } catch (error) {
    return handleError(
      error,
      'An error occurred while sending OTP to your phone number.'
    )
  }
}

/**
 * Sends OTP to registered email address.
 * @returns {Promise<Object>} - The response data or an error object.
 */
const verifyEmail = async () => {
  try {
    const response = await axios.post(`/auth/verify/email`)
    return response.data
  } catch (error) {
    return handleError(
      error,
      'An error occurred while sending OTP to your email address.'
    )
  }
}

/**
 * Checks OTP submitted by user and marks email as verified.
 * @param {Object} data - The OTP code to confirm email.
 * @returns {Promise<Object>} - The response data or an error object.
 */
const confirmEmail = async data => {
  try {
    const response = await axios.post(`/auth/confirm/email`, data)
    return response.data
  } catch (error) {
    return handleError(error, 'An error occurred while submitting OTP.')
  }
}

/**
 * Checks OTP submitted by user and marks phone number as verified.
 * @param {Object} data - The OTP code to confirm phone number.
 * @returns {Promise<Object>} - The response data or an error object.
 */
const confirmPhone = async data => {
  try {
    const response = await axios.post(`/auth/confirm/phone`, data)
    return response.data
  } catch (error) {
    return handleError(error, 'An error occurred while submitting OTP.')
  }
}

/**
 * Gets all the pending actions of the user.
 * @returns {Promise<Object>} - The response data or an error object.
 */
const getPendingAction = async () => {
  try {
    const response = await axios.get('pending-action')
    return response.data
  } catch (error) {
    return handleError(
      error,
      'An error occurred while retreiving pending actions.'
    )
  }
}

/**
 * Resolves a pending action by sending a POST request with the provided data.
 *
 * @param {string} actionId - The unique identifier for the pending action to resolve.
 * @param {Object} data - The data payload to be sent with the request.
 * @returns {Promise<Object>} - The response data from the server if the request is successful.
 */
const resolvePendingAction = async (actionId, data) => {
  try {
    const response = await axios.post(
      `/pending-action/resolve/${actionId}`,
      data
    )
    return response.data
  } catch (error) {
    return handleError(error, 'An error occurred while resolving action.')
  }
}

/**
 * Gets all the exports of the user.
 * @returns {Promise<Object>} - The response data or an error object.
 */
const getAllExports = async (start = 0, limit = 20) => {
  try {
    const response = await axios.get('export-result', {
      params: {
        start,
        limit
      }
    })
    return response.data
  } catch (error) {
    return handleError(error, 'An error occurred while retreiving exports.')
  }
}

/**
 * Gets single exports of the user.
 * @returns {Promise<Object>} - The response data or an error object.
 */
const getExport = async id => {
  try {
    const response = await axios.get(`export-result/?ids=${id}`)
    return response.data
  } catch (error) {
    return handleError(error, 'An error occurred while retreiving exports.')
  }
}

/**
 * Handles API errors, providing fallback error data.
 * @param {Object} error - The error object caught from the Axios request.
 * @param {String} fallbackMessage - A fallback message to return if the error response is unavailable.
 * @returns {Object|String} - The error response data or a fallback message.
 */
const handleError = (error, fallbackMessage) => {
  return error?.response?.data || { message: fallbackMessage }
}

export {
  getBaseUser,
  getUsers,
  startMfaAssociation,
  confirmAssociation,
  disableMfa,
  updateUserDetail,
  pinAutomation,
  unpinAutomation,
  getOrganisationLimits,
  verifyEmail,
  verifyPhone,
  confirmEmail,
  confirmPhone,
  getPendingAction,
  resolvePendingAction,
  getAllExports,
  getExport
}
