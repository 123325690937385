<template>
  <WatchYoutubeVideoModal
    src="https://www.youtube.com/embed/XkkoKARHoy0?si=AP_E2_GfFqKjbchR"
    v-if="modalActive"
    @close="toggleModalClose"
  />
  <div class="flex max-w-xs flex-col justify-between">
    <div class="flex-1 px-4 py-5 sm:p-6" :class="[{ '!py-3': space }]">
      <div>
        <label
          for=""
          class="block text-sm font-medium text-gray-900 dark:text-gray-50"
        >
          Automation Name (Optional):
        </label>
        <div class="mt-1.5">
          <input
            v-model="automationName"
            v-debounce:300ms="saveWorkflowName"
            type="text"
            id="search-input"
            :maxlength="50"
            class="block w-full rounded-lg border border-gray-300 px-3 py-2.5 placeholder-gray-500 caret-blue-600 transition-all duration-200 hover:border-gray-400 focus:border-blue-600 focus:ring-blue-600 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-50 dark:placeholder-gray-400 dark:hover:border-gray-500 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
            placeholder="Enter Automation Name"
          />
          <p class="text-xs mt-1 text-gray-500">
            Maximum character limit: 50 characters
          </p>
        </div>
      </div>

      <ul class="space-y-5 mt-11">
        <li
          v-for="(list, index) in lists"
          :key="index"
          class="flex"
          :class="[{ 'items-start': start }, { 'items-center': center }]"
        >
          <span
            class="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-blue-50 text-xs font-semibold text-blue-600 dark:bg-blue-900/50 dark:text-blue-200"
          >
            {{ index + 1 }}
          </span>
          <div v-if="!!lists[index].text" class="ml-2.5">
            <span
              class="flex-1 text-sm font-normal text-gray-900 dark:text-gray-50"
            >
              {{ lists[index].text }}
            </span>

            <ul
              class="mt-2 list-outside space-y-2 pl-2 text-sm font-normal text-gray-900 dark:text-gray-50"
            >
              <li class="" v-for="color in lists[index].subtext" :key="color">
                <div class="flex items-center gap-2">
                  <button
                    type="button"
                    class="flex items-center justify-center"
                    :class="color.color"
                  >
                    <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 8 8">
                      <circle cx="4" cy="4" r="3" />
                    </svg>
                  </button>
                  {{ color.text }}
                </div>
              </li>
            </ul>
          </div>
          <!-- prettier-ignore -->
          <span
            v-if="! (!!lists[index].text)"
            class="text-sm font-normal flex-1 text-gray-900 dark:text-gray-50 ml-2.5"
          >
            {{ list }}
          </span>
        </li>
      </ul>
    </div>
    <div class="px-4 pt-3 sm:p-6">
      <div
        @click="modalActive = true"
        class="flex cursor-pointer border-[1px] border-blue-600 items-center justify-center gap-4 bg-blue-50 hover:bg-blue-100 rounded-lg py-1"
      >
        <p class="text-sm font-semibold text-blue-600 dark:text-gray-50">
          Watch this video tutorial:
        </p>
        <div>
          <div class="text-blue-600"><SvgIcon name="videcam" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateWorkflow } from '@/apis/workflows'
import WatchYoutubeVideoModal from '@/components/automationStore/WatchYoutubeVideoModal.vue'
import { vue3Debounce } from 'vue-debounce'
import Input from '../Input.vue'
import { mapActions, mapState } from 'vuex'
import SvgIcon from '../SvgIcon.vue'
export default {
  name: 'OptionalSidebar',

  directives: {
    debounce: vue3Debounce({})
  },
  data() {
    return {
      lists: null,
      modalActive: false,
      default: [
        'Fill the Required Inputs',
        'Optional: Schedule the Automation',
        'Run the Automation',
        'Collect the Data'
      ],
      scheduling: [
        '**Optional Step, Only Required to Plan in Advance',
        'Select the Schedule Recurrence (Frequency)',
        'Set Start Time Offset (Optional)',
        'Set End Time Offset (Optional)'
      ],
      run: [
        'Click Run to Launch the Automation.',
        {
          text: 'Wait Until Automation is Completed',
          subtext: [
            { text: 'Green - Completed', color: 'text-green-600' },
            { text: 'Blue - Running', color: 'text-blue-600' },
            { text: 'Red - Failed', color: 'text-red-600' },
            { text: 'Orange - Paused', color: 'text-orange-500' },
            { text: 'Black - Stopped', color: 'text-gray-900' }
          ]
        },
        'Click on Show Results to Preview the Data.'
      ],
      collect: [
        'Wait Until Automation is Completed',
        {
          text: 'Download the Results in CSV or Google Sheet',
          subtext: [
            { text: 'Green - Completed', color: 'text-green-600' },
            { text: 'Blue - Running', color: 'text-blue-600' },
            { text: 'Red - Failed', color: 'text-red-600' },
            { text: 'Orange - Paused', color: 'text-orange-500' },
            { text: 'Black - Stopped', color: 'text-gray-900' }
          ]
        }
      ],
      sheets: [
        'Select the Sheet With Your Data input',
        'Select the Sheet With Your Data input',
        'Select the Colum Header to Process',
        'Run now or Schedule For Later!'
      ],
      automationName: null
    }
  },
  props: {
    type: { type: String, default: 'default' },
    search: { type: Boolean, default: false },
    data: { type: Array },
    item: { type: String }
  },
  created() {
    this.automationName = this.automationNameText
    switch (this.type) {
      case 'default':
        this.lists = this.default
        break
      case 'scheduling':
        this.lists = this.scheduling
        break
      case 'run':
        this.lists = this.run
        break
      case 'collect':
        this.lists = this.collect
        break
      case 'sheets':
        this.lists = this.sheets
        break
      default:
        break
    }
  },
  computed: {
    ...mapState('automationStore', ['automationNameText']),
    start() {
      if (this.item === 'start') {
        return true
      }
    },
    center() {
      if (this.item === 'center') {
        return true
      }
    }
  },
  methods: {
    ...mapActions('automationStore', [
      'setAutomationNameText',
      'setDelayAutomationNaming'
    ]),
    async saveWorkflowName(text) {
      try {
        this.setAutomationNameText({
          payload: this.automationName
        })
        if (!this.$route.query.workflowId) {
          this.setDelayAutomationNaming({
            payload: true
          })
          return
        }
        const response = await updateWorkflow(this.$route.query.workflowId, {
          name: text
        })

        if (!response['success']) {
          throw response.message
        }
      } catch (error) {
        this.$emit('error', error)
      }
    },

    toggleModalClose() {
      this.modalActive = false
    }
  },
  components: { Input, WatchYoutubeVideoModal, SvgIcon }
}
</script>

<style></style>
