import AutomationMatrixPage1 from '@/views/automationMatrix/Page1.vue'

// Onboarding Pages
import addAdditionalDetails from '@/views/onboarding/addAdditionalDetails.vue'
import createFirstWorkspace from '@/views/onboarding/createFirstWorkspace.vue'
import installExtension from '@/views/onboarding/installExtension.vue'
import showAllImportedAccounts from '@/views/onboarding/showAllImportedAccounts.vue'
import showCloudAndDesktop from '@/views/onboarding/showCloudAndDesktop.vue'
import triggerExtension from '@/views/onboarding/triggerExtension.vue'
import updateLocation from '@/views/onboarding/updateLocation.vue'

// settings page
import WorkspaceMain from '@/views/settings/settings-workspace/WorkspaceMain.vue'
import developerApi from '@/views/settings/settings-api-key/developerApi.vue'
import changePaymentMethod from '@/views/settings/settings-billing/changePaymentMethod.vue'
import invoices from '@/views/settings/settings-billing/invoices.vue'
import paymentMethods from '@/views/settings/settings-billing/paymentMethods.vue'
import plan from '@/views/settings/settings-billing/plan.vue'
import VerifyMain from '@/views/settings/settings-verify/VerifyMain.vue'
import allIntegrations from '@/views/settings/settings-integrations/allIntegrations.vue'
// import connectNewAccount from '@/views/settings/settings-integrations/connectNewAccount.vue'
import delegationLink from '@/views/settings/settings-integrations/delegationLink.vue'
// import integrationConnector from '@/views/settings/settings-integrations/integrationConnector.vue'
import createTemplate from '@/views/settings/settings-message-templates/createTemplate.vue'
import messageTemplates from '@/views/settings/settings-message-templates/messageTemplates.vue'
import selectTemplateFolder from '@/views/settings/settings-message-templates/selectTemplateFolder.vue'
import notifications from '@/views/settings/settings-notifications/notifications.vue'
import proxies from '@/views/settings/settings-proxies/proxies.vue'
import scheduleTemplates from '@/views/settings/settings-schedule-templates/scheduleTemplates.vue'
import emailAndPassword from '@/views/settings/settings-security/emailAndPassword.vue'
import twoFactorAuthentication from '@/views/settings/settings-security/twoFactorAuthentication.vue'
import DesktopMain from '@/views/settings/settings-desktop/DesktopMain.vue'
import DeveloperMain from '@/views/settings/settings-developer-mode/DeveloperMain.vue'
import tags from '@/views/settings/settings-tags/tags.vue'
import userRoles from '@/views/settings/settings-user-permissions/userRoles.vue'
import userRolesOrganization from '@/views/settings/settings-user-permissions/userRolesOrganization.vue'
import addClient from '@/views/settings/settings-whitelabel/addClient.vue'
import agency from '@/views/settings/settings-whitelabel/agency.vue'
import clients from '@/views/settings/settings-whitelabel/clients.vue'
import customDomain from '@/views/settings/settings-whitelabel/customDomain.vue'
import reports from '@/views/settings/settings-whitelabel/reports.vue'
import createNewWorkspace from '@/views/settings/settings-workspace/createNewWorkspace.vue'
import profileVariables from '@/views/settings/settings-workspace/profileVariables.vue'
import rateLimits from '@/views/settings/settings-workspace/rateLimits.vue'
import workspaceManagerCloud from '@/views/settings/settings-workspace/workspaceManagerCloud.vue'
import TexauAI from '@/views/settings/settings-texauAI/TexauAI.vue'
//Automation Pages
import AutomationStorePage1 from '@/views/automationStore/Page1.vue'
import AutomationStorePage10 from '@/views/automationStore/Page10.vue'
import AutomationStorePage2 from '@/views/automationStore/Page2.vue'
import AutomationStorePage3 from '@/views/automationStore/Page3.vue'
import AutomationStorePage4 from '@/views/automationStore/Page4.vue'
import AutomationStorePage5 from '@/views/automationStore/Page5.vue'
import AutomationStorePage6 from '@/views/automationStore/Page6.vue'
import AutomationStorePage9 from '@/views/automationStore/Page9.vue'
import DataStoreMain from '@/views/dataStore/DataStoreMain.vue'
import WorkflowResult from '@/views/dataStore/WorkflowResult.vue'
import AffiliateMain from '@/views/settings/settings-affiliate/AffiliateMain.vue'
import workspaceSettings from '@/views/settings/settings-workspaceSettings/workspaceSettings.vue'

//Automation Results
import Results from '@/views/automationresults/Results.vue'

//Public Recipes
import UserProfile from '@/common/userWrapper'
import Redirect from '@/components/automationStore/googleSheets/redirect.vue'
import ComponentsVue from '@/views/Components.vue'
import confirmMailOtp from '@/views/onboarding/confirmMailOtp.vue'
import Importing from '@/views/onboarding/Importing.vue'
import page1 from '@/views/publicRecipes/page1.vue'
import page2 from '@/views/publicRecipes/page2.vue'
import page3 from '@/views/publicRecipes/page3.vue'
import page4 from '@/views/publicRecipes/page4.vue'
import page5 from '@/views/publicRecipes/page5.vue'
import page6 from '@/views/publicRecipes/page6.vue'
import page7 from '@/views/publicRecipes/page7.vue'
import WorkflowBuilder from '@/views/WorkflowBuilder.vue'
import WorkflowBuilderResult from '@/views/WorkflowBuilderResult.vue'

//Invite
import Invites from '@/views/invites/Invites.vue'

//Downloads
import Downloads from '@/views/downloads/Downloads.vue'

import AllNotifications from '@/views/AllNotifications.vue'
import magicLinkIntegration from '@/components/settings/integration/magicLinkIntegration.vue'
import AdminMain from '@/views/admin/AdminMain.vue'
import ExportMain from '@/views/exports/ExportMain.vue'

export const AuthRoutes = [
  {
    path: '/onboarding',
    name: 'onboarding',
    redirect: '/onboarding/step-1'
  },
  {
    path: '/',
    name: 'home',
    redirect: '/accounts'
  },
  {
    path: '/components',
    name: 'Components',
    meta: { requiresDeveloperStatus: true, layout: false },
    component: ComponentsVue
  },
  // {
  //   path: '/automation-store',
  //   name: 'automation-store',
  //   redirect: '/automation-store/page-1'
  // },
  {
    path: '/onboarding/confirm-mail-otp',
    name: 'Confirm Mail',
    meta: { layout: false },
    component: confirmMailOtp,
    beforeEnter: () => {
      // reject the navigation
      if (UserProfile.getUser().isEmailVerified) {
        return {
          name: 'home',
          replace: true
        }
      }
    }
  },
  {
    path: '/onboarding/create-workspace',
    meta: { layout: false },
    name: 'Create First Workspace',
    component: createFirstWorkspace
  },
  {
    path: '/onboarding/additional-details',
    meta: { layout: false },
    name: 'Add Additional Details',
    component: addAdditionalDetails
  },
  {
    path: '/onboarding/install-extension',
    meta: { layout: false },
    name: 'Install Extension',
    component: installExtension
  },
  {
    path: '/onboarding/start-import',
    meta: { layout: false },
    name: 'Trigger Extension',
    component: triggerExtension
  },
  {
    path: '/onboarding/importing',
    meta: { layout: false },
    name: 'Importing',
    component: Importing
  },
  {
    path: '/onboarding/accounts',
    meta: { layout: false },
    name: 'Accounts',
    component: showAllImportedAccounts
  },
  {
    path: '/onboarding/cloud-and-desktop',
    meta: { layout: false },
    name: 'Cloud and Desktop',
    component: showCloudAndDesktop
  },
  {
    path: '/onboarding/update-data',
    meta: { layout: false },
    name: 'Update Data',
    component: updateLocation
  },
  {
    path: '/automation-store',
    name: 'Automation Store',
    component: AutomationStorePage1
  },
  {
    path: '/automation-store/workflow/create',
    name: 'Automation Input Source',
    component: AutomationStorePage2
  },
  {
    path: '/automation-store/workflow',
    name: 'Automation Run Main',
    component: AutomationStorePage3
  },
  {
    path: '/automation-store/results',
    name: 'Results',
    component: Results
  },
  {
    path: '/oauth/:appName/redirect',
    name: 'Redirect',
    component: Redirect
  },
  {
    path: '/automation-store/page-4',
    name: 'AutomationStorePage4',
    component: AutomationStorePage4
  },
  {
    path: '/automation-store/page-5',
    name: 'AutomationStorePage5',
    component: AutomationStorePage5
  },
  {
    path: '/automation-store/page-6',
    name: 'AutomationStorePage6',
    component: AutomationStorePage6
  },

  {
    path: '/automation-store/page-9',
    name: 'AutomationStorePage9',
    component: AutomationStorePage9
  },
  {
    path: '/automation-store/page-10',
    name: 'AutomationStorePage10',
    component: AutomationStorePage10
  },
  {
    path: '/automation-matrix/',
    name: 'Automation Matrix',
    component: AutomationMatrixPage1
  },
  {
    path: '/settings',
    redirect: '/settings-user-permissions/user-roles'
  },
  {
    path: '/settings-api-key/developer-api',
    name: 'Developer API',
    component: developerApi
  },
  {
    path: '/settings-notifications/notifications',
    name: 'Notification Settings',
    component: notifications
  },
  {
    path: '/settings-billing/invoices',
    name: 'Invoices',
    component: invoices
  },
  {
    path: '/settings-billing/payment-methods',
    name: 'Payment Methods',
    component: paymentMethods
  },
  {
    path: '/settings-billing/plan',
    name: 'Plans',
    component: plan
  },
  {
    path: '/settings-billing/change-payment-method',
    name: 'Change Payment Method',
    component: changePaymentMethod
  },
  {
    path: '/settings-security/2-factor-authentication',
    name: '2 Factor Authentication',
    component: twoFactorAuthentication
  },
  {
    path: '/settings-security',
    name: 'Email and Password',
    component: emailAndPassword
  },
  {
    path: '/settings-desktop',
    name: 'Desktop',
    component: DesktopMain
  },
  {
    path: '/settings-developer-mode',
    name: 'Developer Mode',
    component: DeveloperMain
  },
  {
    path: '/settings-whitelabel/add-client',
    name: 'Add Client',
    component: addClient
  },
  {
    path: '/settings-verify',
    name: 'VerifyMain',
    component: VerifyMain
  },
  {
    path: '/settings-whitelabel/agency',
    name: 'Agency',
    component: agency
  },
  {
    path: '/settings-whitelabel/clients',
    name: 'Clients',
    component: clients
  },
  {
    path: '/settings-whitelabel/custom-domain',
    name: 'Custom Domain',
    component: customDomain
  },
  {
    path: '/settings-workspace/create-new-workspace',
    name: 'Create New workspace',
    component: createNewWorkspace
  },
  {
    path: '/settings-workspace/profile-variables',
    name: 'Profile Variables',
    component: profileVariables
  },
  {
    path: '/settings-workspace/rate-limits',
    name: 'Rate Limits',
    component: rateLimits
  },
  {
    path: '/accounts',
    name: 'User Accounts',
    component: WorkspaceMain
  },
  {
    path: '/settings-message-templates/create-template',
    name: 'Create Template',
    component: createTemplate
  },
  {
    path: '/settings-message-templates/message-templates',
    name: 'Message Templates',
    component: messageTemplates
  },
  {
    path: '/settings-integrations/delegation-link',
    name: 'Delegation Link',
    component: delegationLink
  },
  {
    path: '/settings-integrations/all-integrations',
    name: 'All Integrations',
    component: allIntegrations
  },
  // {
  //   path: '/settings-integrations/connect-new-account',
  //   name: 'Connect New Account',
  //   component: connectNewAccount
  // },
  // might need for later use
  // {
  //   path: '/settings-integrations/:id',
  //   name: 'Integration Connector',
  //   component: integrationConnector
  // },
  {
    path: '/settings-message-templates/select-template-folder',
    name: 'Select Template Folder',
    component: selectTemplateFolder
  },
  {
    path: '/settings-tags/tags',
    name: 'Tags',
    component: tags,
    meta: { requiresDeveloperStatus: true }
  },
  {
    path: '/settings-whitelabel/reports',
    name: 'Reports',
    component: reports
  },
  {
    path: '/settings-user-permissions/user-roles',
    name: 'User Roles',
    component: userRoles
  },
  {
    path: '/settings-user-permissions/user-roles-organization',
    name: 'User Roles Organization',
    component: userRolesOrganization
  },
  {
    path: '/settings-workspace',
    name: 'WorkspaceSettings',
    component: workspaceSettings
  },
  {
    path: '/settings-schedule-templates/schedule-templates',
    name: 'Schedule Templates',
    component: scheduleTemplates
  },
  {
    path: '/settings-affiliate',
    name: 'Partners Program',
    component: AffiliateMain
  },
  {
    path: '/ai-prompt-builder',
    name: 'TexAu AI',
    component: TexauAI
  },
  {
    path: '/settings-proxies',
    name: 'Proxies',
    component: proxies
  },
  {
    path: '/public-recipes',
    meta: { layout: false },
    name: 'Public Recipe',
    component: page1
  },
  {
    path: '/public-recipes/page-2',
    name: 'Public Recipe Page 2',
    component: page2
  },
  {
    path: '/public-recipes/page-3',
    name: 'Public Recipe Page 3',
    component: page3
  },
  {
    path: '/public-recipes/page-4',
    name: 'Public Recipe Page 4',
    component: page4
  },
  {
    path: '/public-recipes/page-5',
    name: 'Public Recipe Page 5',
    component: page5
  },
  {
    path: '/public-recipes/page-6',
    name: 'Public Recipe Page 6',
    component: page6
  },
  {
    path: '/public-recipes/page-7',
    name: 'Public Recipe Page 7',
    component: page7
  },
  {
    path: '/data-store/results',
    name: 'Data Store Results',
    component: WorkflowResult
  },
  {
    path: '/data-store',
    name: 'Data Store',
    component: DataStoreMain
  },
  {
    path: '/workflows/:id/results',
    name: 'Workflow Result',
    component: WorkflowBuilderResult
  },
  {
    path: '/workflow-builder/:id?',
    name: 'Workflow Builder',
    component: WorkflowBuilder
  },
  {
    path: '/invites',
    name: 'Invites',
    component: Invites
  },
  {
    path: '/downloads',
    name: 'Downloads',
    component: Downloads
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: AllNotifications
  },
  {
    path: '/new-account',
    name: 'Magic Link Integration',
    component: magicLinkIntegration
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminMain
  },
  {
    path: '/exports',
    name: 'Exports',
    component: ExportMain
  },
  { path: '/:path(.*)', redirect: '/' }
]
