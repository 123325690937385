<template>
  <div
    class="relative flex flex-1 border-l border-gray-200 dark:border-gray-700"
  >
    <div class="relative flex flex-1 flex-col justify-between px-4 py-5 sm:p-6">
      <div v-if="loading" class="flex items-center h-[250px] justify-center">
        <Spinner size="large" />
      </div>
      <div v-else class="mt-2 flex flex-col gap-2" :key="reRender">
        <p class="text-center text-lg font-medium mb-3">
          How much time should be added as a delay between Executions?
        </p>

        <div class="flex flex-col w-full gap-3">
          <p class="font-[500] text-center">Minimum Delay</p>

          <div class="flex gap-2">
            <div class="w-full">
              <label
                class="block text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                Time Unit:
              </label>
              <Select
                v-model="iterationDelayConfig.minDelay.delayUnit"
                :options="['seconds', 'minutes', 'hours', 'days']"
              />
            </div>
            <div class="w-full">
              <label
                class="block text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                Delay Value:
              </label>
              <Input
                v-model.number="iterationDelayConfig.minDelay.delayValue"
                text="Delay Value"
                type="number"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-col w-full gap-3">
          <p class="font-[500] text-center">Maximum Delay</p>
          <div class="flex gap-2">
            <div class="w-full">
              <label
                class="block text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                Time Unit:
              </label>
              <Select
                v-model="iterationDelayConfig.maxDelay.delayUnit"
                :options="['seconds', 'minutes', 'hours', 'days']"
              />
            </div>
            <div class="w-full">
              <label
                class="block text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                Delay Value:
              </label>
              <Input
                v-model.number="iterationDelayConfig.maxDelay.delayValue"
                text="Delay Value"
                type="number"
              />
            </div>
          </div>
        </div>
        <div class="pt-4">
          <Button
            color="primary"
            text="Save Delay"
            @click="saveIterationDelay()"
          />
        </div>
      </div>

      <div class="mt-auto flex items-center justify-between">
        <Button
          color="tertiary"
          text="Previous"
          leftIcon="left-arrow"
          @click="prevButtonClick()"
        />

        <div class="flex items-center gap-2">
          <p class="text-red-600 font-bold">Important:</p>
          <p class="text-red-600">
            Once an execution has been run, you cannot update the iteration
            delay.
          </p>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import Input from '@/components/Input.vue'
import Select from '@/components/Select.vue'
import { mapState, mapActions } from 'vuex'
import Spinner from '@/components/Spinner.vue'
import { getWorkflowAllNodes, updateWorkflowNode } from '@/apis/workflows'
export default {
  name: 'Iteration Delay',
  components: {
    Select,
    Input,
    Button,
    Spinner
  },
  data() {
    return {
      iterationDelayConfig: {
        minDelay: {
          delayValue: 5,
          delayUnit: 'seconds'
        },
        maxDelay: {
          delayValue: 9,
          delayUnit: 'seconds'
        }
      },
      reRender: 0,
      nodeData: null,
      loading: true
    }
  },
  computed: {
    ...mapState('automationStore', ['iterationDelay']),
    ...mapState('automationStore', ['automationStoreData'])
  },
  async mounted() {
    if (this.iterationDelay) {
      this.iterationDelayConfig = this.iterationDelay
    } else if (this.$route.query.workflowId) {
      const response = await getWorkflowAllNodes(this.$route.query.workflowId)
      this.nodeData = response.data[1]
      if (this.nodeData.iterationDelayConfig) {
        this.iterationDelayConfig = this.nodeData.iterationDelayConfig
      } else if (this.automationStoreData.operation.defaultIterationDelay) {
        this.setDefaultIterationDelayConfig()
      }
    } else if (this.automationStoreData.operation.defaultIterationDelay) {
      this.setDefaultIterationDelayConfig()
    }
    this.loading = false
    this.reRender++
  },
  methods: {
    ...mapActions('automationStore', ['changeIterationDelay']),
    prevButtonClick() {
      this.$emit('nextButtonClick', {
        comp: 'scheduling-comp',
        highlight: 2,
        optionalSidebarType: 'scheduling',
        optionalSidebarItem: 'center',
        optionalSidebarSearch: false
      })
    },
    setDefaultIterationDelayConfig() {
      const defaultVariation =
        0.2 * this.automationStoreData.operation.defaultIterationDelay
      this.iterationDelayConfig.maxDelay.delayValue =
        (this.automationStoreData.operation.defaultIterationDelay +
          defaultVariation) /
        1000
      this.iterationDelayConfig.minDelay.delayValue =
        (this.automationStoreData.operation.defaultIterationDelay -
          defaultVariation) /
        1000
      this.iterationDelayConfig.maxDelay.delayUnit = 'seconds'
      this.iterationDelayConfig.minDelay.delayUnit = 'seconds'
    },
    saveIterationDelay() {
      if (
        this.iterationDelayConfig.maxDelay.delayValue === 0 &&
        this.iterationDelayConfig.minDelay.delayValue === 0
      ) {
        return
      }
      this.changeIterationDelay({
        payload: this.iterationDelayConfig
      })
      this.$emit('success', 'Iteration Delay Saved Successfully')
    }
  }
}
</script>
